import React, { useState, useEffect } from 'react'
import { BigHead } from '@bigheads/core'
import { Tooltip as ReactTooltip } from "react-tooltip";
import api from '../../../services/api';
const TableListTop = ({ id, client, onClick, state }) => {

    const [usersInCountage, setUserInCountage] = useState([])
    const [users, setUsers] = useState([])


    const getUsersInCountage = async () => {

        const response = await api.post("user/countages", {
            "client": client,
        })

        //setUserInCountage(response.data)

        const newArray = response.data.filter(item => item.id_countage == id)

        setUserInCountage(newArray)

        console.log(newArray)

    }

    const onClickModalList = (e) => {

        if (e.target.id !== "close") {
            onClick()
        }
    }

    const removeUser = async (idUser) => {

        await api.delete(`user/countages/${idUser}`)


        const response = await api.post("user/countages", {
            "client": client,
        })

        const newArray = response.data.filter(item => item.id_countage == id)

        setUserInCountage(newArray)
    }


    return (

        <div style={{ minWidth: "1925px", width: usersInCountage.length == 1 || usersInCountage.length == 0 ? "190px" : "auto", height: "48px", border: "1px solid #D7D7D7", borderRadius: "7px", cursor: "pointer",background:"white",marginBottom:"2rem" }}>

            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>


                <div data-tooltip-place='top' data-tooltip-position-strategy='fixed' data-tooltip-id="name" style={{ display: "flex", alignItems: "center", height: "35px", marginTop: "0.3rem", marginLeft: "0.5rem", marginRight: "0.5rem",gap: "5px", padding: "0 0 0 12px", borderRadius: "60px" }}>
                    <ReactTooltip id='name' />
                    <p className='textCardUserTable' style={{ transform: "translateY(8px)" }}></p>
                    <div style={{ transform: "translateY(-2px)" }}>

                        
                    <div style={{width:"37px"}}></div>

                    </div>


                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>Status</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>Finalidade</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>Localidade</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>Tipo imóvel</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>Descrição</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>Qtd Banheiros</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>Bairro</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>Iptu</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>Metros m²</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>Foto</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>Valor</p>
                    <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>Código</p>



                </div>



                <div onClick={onClick} style={{ width: "100%", height: "44px" }}>

                </div>
            </div>
        </div>

    )

}

export default TableListTop