import React,{useState,useEffect} from 'react'
import api from '../../services/api'
import camaIcon from '../../images/png/cama-de-casal.png'
import vasoSanitarioIcon from '../../images/png/vaso-sanitario.png'
import copiaDeIcon from '../../images/png/copia-de.png'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const CardImmobileMobile = ({id_prop}) => {

    const [imageValue, setImageValue] = useState()
    const [city, setCity] = useState()
    const [state, setState] = useState()

    const [code, setCode] = useState()
    const [squareMeters, setSquareMeters] = useState()
    const [bathroomNum, setBathroomNum] = useState()
    const [beadNum, setBeadNum] = useState()
    const [description, setDescription] = useState()
    
    const get = async () => {

        const response = await api.get(`panel/immobile/${id_prop}`)
        setCode(response.data[0].code)
        setSquareMeters(response.data[0].square_meters)
        setBathroomNum(response.data[0].bathroom_num)
        setBeadNum(response.data[0].bead_num)
        setDescription(response.data[0].description)
    }

    const getImage = async () => {

        const response = await api.get(`/client/immobiles/images/${id_prop}`)
        setImageValue(response.data[0].image)
    }
    const addresses = async () => {
        const response = await api.get(`client/immobiles/addresses/${id_prop}`)
        setCity(response.data[0].city)
        setState(response.data[0].state)

    }
    const redirectToImmobilePage = () => {
        
        localStorage.setItem("@IMMOBILE_ID", id_prop)
        window.location.href = "/animate"
    }

    useEffect(() => {

        getImage()
        addresses()
        get()
    }, [id_prop])

    
    return(

        imageValue == undefined 
        
            ?
            <Skeleton style={{height:"320px",marginBottom:"1.5rem",borderRadius:"5px",width:"320px"}}/>
        
            :

    
        

        <div className='cardImmobileMobile' onClick={() => redirectToImmobilePage()}>

            <div className='cardImmobileMobileHeader'>

                <img src={imageValue}/>

            </div>

            <div className='cardImmobileMobileBody'>
            <p className='textPrimaryContainerCardImmobile'>{city} /{state}</p>
                    <p className='textCodeContainerCardImmobile'>Código imóvel: {code}</p>

                  <div className='row_infoCardImmobile'>

                        <div className='cardRowInfo_cardImmobile'>
                            <img src={camaIcon} className='cardInfoIcon' />
                            <p>{beadNum}</p>
                        </div>


                        <div className='cardRowInfo_cardImmobile'>
                            <img src={vasoSanitarioIcon} style={{ width: "35px" }} className='cardInfoIcon' />
                            <p>{bathroomNum}</p>
                        </div>



                        <div className='cardRowInfo_cardImmobile'>
                            <img src={copiaDeIcon} className='cardInfoIcon' />
                            <p>{squareMeters} m²</p>
                        </div>


                    </div>

                    <p className='textDescriptionContainerCardImmobile'>{description}</p>



                    <buton className="btnPrimaryContainerCardImmobile">Ver imóvel</buton>
            </div>

        </div>

    )
}

export default CardImmobileMobile 