import React from 'react'

/* IMAGES PNG */
import locationIcon from '../../images/png/locationIcon.png'

const HeaderPhone = () => {

    return (

        <div className='containerFlex_headerPhone'>

        

            
            <div className='secondaryColumn_headerPhone'>
                <img src={locationIcon} />
                <p className='textSecondaryColumn_headerPhone'>Av. João Barbosa de Moraes, 758  </p>
            </div>

            

        </div>
    )
}

export default HeaderPhone