import React,{useState} from 'react'
import Header from '../../components/Header'
import HeaderSecondary from '../../components/HeaderSecondary'
import familyIcon from '../../images/svg/contact.svg'
import InputContact from '../../components/InputContact'
import { ToastContainer,toast} from 'react-toastify';
import arrowIcon from '../../images/png/arrowRight.png'
import api from '../../services/api'
const Contact = () => {

    const [name,setName] = useState()
    const [mail,setMail] = useState()
    const [description,setDescription] = useState()

    const storage = async () =>{

        if(name == undefined || name == ""){
                    
              toast.error('Informe seu nome!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
        

        if(mail == undefined || mail == ""){
                            
              return toast.error('Informe seu email!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false

        }

        if(description == undefined || description == ""){
             return toast.error('Informe uma descrição!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false    

        }

        const response = await api.post("client/storage/mail",{
            "name":name,
            "mail":mail,
            "description":description
        })

        if(response.status == 200){
            toast.success('Email enviado com sucesso!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setTimeout(() => {
                window.location.href="/contact"
            },[2000])
        }

    }
    
    return (

        <main>
                <ToastContainer/>
            <Header background={"black"} />
            <HeaderSecondary  page="immobile" background={"black"} />

            <section className='container_about'>

                <div className='container_input_contact'>
                    <p className='textPrimary_containerAbout'>Contato</p>
                    <p className='textSecondary_containerAbout'>Cajamar Imóveis</p>
                    <p className='textThree_containerAbout'>Estamos prontos para ajudá-lo  em sua jornada imobiliária!</p>

                    <InputContact onChange={(e) => setName(e.target.value)} name="Nome completo"/>
                    <InputContact onChange={(e) => setMail(e.target.value)} name="Email"/>
                    <InputContact value={description} onChange={(e) => setDescription(e.target.value)} type="description" name="Descrição"/>


                    <div >
                        <button className='btn_container_about' onClick={() => storage()}>Enviar</button>
                    </div>

                </div>


                <img src={familyIcon} />

                <div className='container_button_about_mobile'>
                        <button className='btn_container_about'>Enviar</button>
                    </div>



            </section>


        </main>

    )
}

export default Contact