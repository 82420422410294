import React,{createContext,useState} from 'react'

export const MenuContext = createContext({})

function MenuProvider({children}){

    const [isOpen,setIsOpen] = useState(false)
    
    const update = () => {

        setIsOpen(!isOpen)

    }

    return(
    <MenuContext.Provider value={{isOpen,update}}>
        {children}
    </MenuContext.Provider>
    )

}

export default MenuProvider