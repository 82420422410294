import React, { useEffect, useState,useContext } from 'react'
import { ImagesContext } from '../../context/images'
import AutoPlay from '../../components/AutoPlay'
import AutoPlayMobile from '../../components/AutoPlayMobile'

import Header from '../../components/Header'
import HeaderSecondary from '../../components/HeaderSecondary'
import kitchenIcon from '../../images/png/kitchen-spoons-icon.png'
import water from '../../images/png/tap-water-icon.png'
import battery from '../../images/png/bulb-light-icon.png'
import quadraIcon from '../../images/png/quadra-de-basquete.png'
import playGroundIcon from '../../images/png/playground.png'
import casaIcon from '../../images/png/casa.png'
import portariaIcon from '../../images/png/porteiro.png'
import salaoIcon from '../../images/png/salao.png'
import sacadaIcon from '../../images/png/sacada.png'
import piscinaIcon from '../../images/png/piscina.png'
import animationGif from '../../images/png/untitled-5.gif'
import washingMachine from '../../images/png/washing-machine-icon.png'
import api from '../../services/api'
import Footer from '../../components/Footer'
const Immobile = () => {

  const {images} = useContext(ImagesContext)

  const [animation, setAnimation] = useState(false)

  const [description, setDescription] = useState()
  const [formatedValue, setFormatedValue] = useState()
  const [squareMeters, setSquareMeters] = useState()
  const [beadNum, setBeadNum] = useState()
  const [bathroomNum, setBathroomNum] = useState()
  const [zipCode, setZipCode] = useState()
  const [state, setState] = useState()
  const [city, setCity] = useState()
  const [number, setNumber] = useState()
  const [code, setCode] = useState()


  /* OPTIONS IMMOBILE */
  const [haveWater, setHaveWater] = useState(false)
  const [kitchen, setKitchen] = useState(false)
  const [energy, setEnergy] = useState(false)
  const [yard, setYard] = useState(false)
  const [laundry, setLaundry] = useState(false)
  const [barbecue, setBarbecue] = useState(false)
  const [serviceArea, setServiceArea] = useState(false)
  const [pet, setPet] = useState(false)
  const [playGround, setPlayGround] = useState()


  const get = async () => {

    setAnimation(true)

    const response = await api.get(`client/immobiles/info/${localStorage.getItem("@IMMOBILE_ID")}`)
    const responseAdresses = await api.get(`client/immobiles/addresses/${localStorage.getItem("@IMMOBILE_ID")}`)

    if (response.status == 200 && responseAdresses.status == 200 && images == 200) {
      setTimeout(() => {
        setAnimation(false)
      },[2000])

    }

    setZipCode(responseAdresses.data[0].cep)
    setState(responseAdresses.data[0].state)
    setCity(responseAdresses.data[0].city)
    setNumber(responseAdresses.data[0].number)

    if (response.status == 404) {
      return false
    }

    setDescription(response.data[0].description)
    setSquareMeters(response.data[0].square_meters)
    setBeadNum(response.data[0].bead_num)
    setBathroomNum(response.data[0].bathroom_num)
    setCode(response.data[0].code)

    const valorFormatado = (Number(response.data[0].value)).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    setFormatedValue(valorFormatado)


  }
  const getCharacter = async () => {
    const response = await api.get(`client/immobiles/character/${localStorage.getItem("@IMMOBILE_ID")}`)

    if (response.status == 404) {
      return false
    }

    response.data.map(item => {
      if (item.name == "Edícula") {
        setHaveWater(true)
      }

      if (item.name == "Portaria 24 horas") {
        setKitchen(true)
      }

      if (item.name == "Piscina") {
        setEnergy(true)
      }

      if (item.name == "Sacada") {

        setYard(true)
      }

      if (item.name == "Salão de festas") {

        setLaundry(true)
      }

      if (item.name == "Churrasqueira") {

        setBarbecue(true)
      }

      if (item.name == "Quadra poliesportiva") {
        setServiceArea(true)
      }

      if (item.name == "Pet") {
        setPet(true)
      }

      if (item.name == "Playground") {
        setPlayGround(true)
      }



    })

    if (response.status == 404) {
      return false
    }
  }

  useEffect(() => {
    get()
    getCharacter()
  }, [images])
  

  return (

    <>
      {animation == true

        ?


        <section className='animation'>
            <img src={animationGif}/>
        </section>
    :
        <div className='animate__animated animate__fadeIn'>
          <Header background={"black"} />
          <HeaderSecondary page="immobile" height={"160px"} background="black" />
          <AutoPlay />
          <AutoPlayMobile />

          <section className='immobile_container'>
            <div className='columPrimaryImmobileContainer'>

              <div>
                <p className='textSecondaryImmobileContainer'>Descrição</p>
                <div className='containerDescriptionImmobileContainer'>
                  <p className='textPrimaryContainerDescription'>{description}</p>
                  <hr />
                </div>

                <div>
                  <p className='textSecondaryImmobileContainerCharacter'>Características da Propriedade
                  </p>
                  <div>
                    <p className='textThreeImmobileContainerCharacter'>Detalhes da propriedade</p>
                  </div>

                  <div className='rowCharacterImmobile'>

                    <div>
                      <div className='cardImmobileCharacter'>

                        <div className='columPrimaryCardImmobileCharacter'>
                          <svg class="qodef-ei-svg-icon qodef--size" width="18.214" height="19.601" viewBox="0 0 18.214 19.601">
                            <path d="M7.87.151h10.013a.181.181 0 0 1 .181.179v2.4a.181.181 0 1 1-.362 0V.511H8.051v4.224a.181.181 0 0 1-.181.181H.511v14.173h17.191V6.296h-6.421v2.139a.181.181 0 1 1-.362 0v-2.32a.181.181 0 0 1 .181-.181h6.783a.181.181 0 0 1 .181.181V19.27a.181.181 0 0 1-.181.181H.331a.181.181 0 0 1-.181-.181V4.735a.181.181 0 0 1 .181-.181h7.358V.33A.181.181 0 0 1 7.87.151Z" fill="currentColor" stroke="currentColor" stroke-width=".3"></path>
                            <path d="M11.1 11.249h2.158a.181.181 0 0 1 0 .362h-1.977v1.669a.181.181 0 0 1-.181.181H.33a.181.181 0 0 1 0-.362h10.589v-1.67a.181.181 0 0 1 .181-.18Z" fill="currentColor" stroke="currentColor" stroke-width=".3"></path>
                            <path d="M16.121 11.249h1.762a.181.181 0 0 1 0 .362h-1.762a.181.181 0 0 1 0-.362Z" fill="currentColor" stroke="currentColor" stroke-width=".3"></path>
                            <path d="M11.1 15.83a.181.181 0 0 1 .181.181v3.259a.181.181 0 1 1-.362 0v-3.259a.181.181 0 0 1 .181-.181Z" fill="currentColor" stroke="currentColor" stroke-width=".3"></path>
                          </svg>

                          <p>Dimensão</p>
                        </div>

                        <div>
                          <p>{squareMeters} M²</p>
                        </div>

                      </div>

                      <div className='cardImmobileCharacter'>

                        <div className='columPrimaryCardImmobileCharacter'>
                          <svg class="qodef-ei-svg-icon qodef--pool" width="24.017" height="17.998" viewBox="0 0 24.017 17.998">
                            <path d="M21.333 8.065H2.683a.355.355 0 0 1-.355-.355V3.059A3.063 3.063 0 0 1 5.387 0h13.242a3.063 3.063 0 0 1 3.059 3.059V7.71a.355.355 0 0 1-.355.355Zm-18.295-.71h17.94v-4.3A2.352 2.352 0 0 0 18.629.706H5.387a2.352 2.352 0 0 0-2.349 2.349Z" fill="currentColor"></path>
                            <path d="M12.008 8.065H4.421a.355.355 0 0 1-.355-.355V4.719a2.128 2.128 0 0 1 2.125-2.125h4.046a2.128 2.128 0 0 1 2.125 2.125V7.71a.355.355 0 0 1-.354.355Zm-7.232-.71h6.877V4.719a1.417 1.417 0 0 0-1.415-1.415H6.192a1.417 1.417 0 0 0-1.415 1.415Z" fill="currentColor"></path>
                            <path d="M19.59 8.065h-7.587a.355.355 0 0 1-.355-.355V4.719a2.128 2.128 0 0 1 2.125-2.125h4.046a2.127 2.127 0 0 1 2.125 2.125V7.71a.355.355 0 0 1-.354.355Zm-7.232-.71h6.877V4.719a1.417 1.417 0 0 0-1.415-1.415h-4.046a1.417 1.417 0 0 0-1.415 1.415Z" fill="currentColor"></path>
                            <path d="M23.662 16.844H.355A.355.355 0 0 1 0 16.489v-5.717a3.421 3.421 0 0 1 3.417-3.417h17.182a3.421 3.421 0 0 1 3.418 3.417v5.717a.355.355 0 0 1-.355.355Zm-22.951-.71h22.6v-5.362a2.71 2.71 0 0 0-2.707-2.707H3.417a2.71 2.71 0 0 0-2.706 2.707Z" fill="currentColor"></path>
                            <path d="M2.637 17.997a.355.355 0 0 1-.355-.355v-1.14a.355.355 0 1 1 .71 0v1.14a.355.355 0 0 1-.355.355Z" fill="currentColor"></path>
                            <path d="M21.333 17.997a.355.355 0 0 1-.355-.355v-1.14a.355.355 0 1 1 .71 0v1.14a.355.355 0 0 1-.355.355Z" fill="currentColor"></path>
                          </svg>
                          <p>Dormitórios</p>
                        </div>

                        <div>
                          <p>{beadNum}</p>
                        </div>

                      </div>

                      <div className='cardImmobileCharacter'>

                        <div className='columPrimaryCardImmobileCharacter'>
                          <svg class="qodef-ei-svg-icon qodef--bathrooms" width="28.528" height="21.994" viewBox="0 0 28.528 21.994">
                            <path d="M21.283 20.649H7.252c-2.119 0-5.529-3.517-5.529-5.7V12.36a.383.383 0 0 1 .383-.383h24.316a.383.383 0 0 1 .383.378v2.593c0 2.184-3.406 5.701-5.522 5.701Zm-18.8-7.909v2.207c0 1.8 3.028 4.937 4.764 4.937h14.031c1.734 0 4.757-3.138 4.757-4.937v-2.206Z" fill="currentColor"></path>
                            <path d="M5.057 10.882a.383.383 0 0 1-.383-.383V3.382A3.5 3.5 0 0 1 5.6.853a2.921 2.921 0 0 1 2.094-.854 2.979 2.979 0 0 1 3.045 2.755.38225907.38225907 0 1 1-.762.062A2.228 2.228 0 0 0 7.694.764a2.162 2.162 0 0 0-1.551.627 2.759 2.759 0 0 0-.7 1.991v7.117a.383.383 0 0 1-.386.383Z" fill="currentColor"></path>
                            <path d="M27.525 12.74H1.003a1 1 0 0 1-1-1v-.377a1 1 0 0 1 1-1h26.522a1 1 0 0 1 1 1v.377a1 1 0 0 1-1 1ZM1.003 11.123a.24.24 0 0 0-.238.237v.377a.241.241 0 0 0 .238.237h26.522a.241.241 0 0 0 .238-.237v-.377a.24.24 0 0 0-.238-.237Z" fill="currentColor"></path>
                            <path d="M12.648 5.466a.383.383 0 0 1-.382-.383 1.917 1.917 0 0 0-3.833 0 .383.383 0 0 1-.765 0 2.682 2.682 0 1 1 5.363 0 .383.383 0 0 1-.383.383Z" fill="currentColor"></path>
                            <path d="M13.505 5.824H7.194a.383.383 0 1 1 0-.765h6.311a.383.383 0 0 1 0 .765Z" fill="currentColor"></path>
                            <path d="M5.917 21.995a.383.383 0 0 1-.293-.629l1.128-1.344a.383.383 0 0 1 .586.492l-1.129 1.344a.38.38 0 0 1-.292.137Z" fill="currentColor"></path>
                            <path d="M22.61 21.995a.38.38 0 0 1-.293-.137l-1.129-1.344a.383.383 0 0 1 .586-.492l1.131 1.344a.383.383 0 0 1-.293.629Z" fill="currentColor"></path>
                          </svg>
                          <p>Banheiros</p>
                        </div>

                        <div>
                          <p>{bathroomNum}</p>
                        </div>

                      </div>
                    </div>

                    <div>
                      <div className='cardImmobileCharacter'>

                        <div className='columPrimaryCardImmobileCharacter'>

                          <img className='icon_immobile_character' src={playGroundIcon} />

                          <p>PlayGround</p>
                        </div>

                        <div>
                          <p>{playGround == true ? "Sim" : "Não"}</p>
                        </div>

                      </div>

                      <div className='cardImmobileCharacter'>

                        <div className='columPrimaryCardImmobileCharacter'>
                          <svg class="qodef-ei-svg-icon qodef--pet-friendly" width="27.7" height="20.6" viewBox="0 0 27.7 20.6">
                            <g transform="translate(1.933 5.165)">
                              <g>
                                <path fill="currentColor" d="M2.6,8.2c-1.2,0-2.1-1-2.1-2.3c0-0.4,0.1-0.8,0.3-1.2l0,0l2.6-3.9c0.6-1,1.8-1.2,2.7-0.6
              c0.2,0.2,0.4,0.4,0.6,0.6l2.6,3.8c0.6,1,0.4,2.4-0.6,3.1C8.3,8.2,7.7,8.3,7.2,8.1L5.7,7.8c-0.3-0.1-0.7-0.1-1,0L3.1,8.1
              C3,8.2,2.8,8.2,2.6,8.2z M1.5,5.1C1.1,5.8,1.3,6.8,1.9,7.2C2.2,7.4,2.6,7.5,3,7.4L4.5,7c0.5-0.1,0.9-0.1,1.4,0l1.5,0.4
              C8.1,7.6,8.8,7.1,9,6.3c0.1-0.4,0-0.8-0.2-1.2L6.2,1.3C5.8,0.7,5.1,0.5,4.5,0.9C4.3,1,4.2,1.1,4.1,1.3L1.5,5.1z"></path>
                              </g>
                            </g>
                            <g>
                              <ellipse fill="none" stroke="currentColor" stroke-width="0.8" cx="12.2" cy="6.3" rx="1.6" ry="2"></ellipse>
                              <ellipse fill="none" stroke="currentColor" stroke-width="0.8" cx="9.2" cy="2.4" rx="1.6" ry="2"></ellipse>
                              <ellipse fill="none" stroke="currentColor" stroke-width="0.8" cx="4.8" cy="2.4" rx="1.6" ry="2"></ellipse>
                              <ellipse fill="none" stroke="currentColor" stroke-width="0.8" cx="2" cy="6.3" rx="1.6" ry="2"></ellipse>
                            </g>
                            <g transform="translate(1.933 5.165)">
                              <g>
                                <path fill="currentColor" d="M16.1,15.4c-1.2,0-2.1-1-2.1-2.3c0-0.4,0.1-0.8,0.3-1.2l0,0L17,8.1c0.6-1,1.8-1.2,2.7-0.6
              c0.2,0.2,0.4,0.4,0.6,0.6l2.6,3.8c0.6,1,0.4,2.4-0.6,3.1c-0.5,0.3-1.1,0.5-1.6,0.3L19.2,15c-0.3-0.1-0.7-0.1-1,0l-1.5,0.4
              C16.5,15.4,16.3,15.4,16.1,15.4z M15,12.4c-0.4,0.7-0.2,1.6,0.4,2.1c0.3,0.2,0.7,0.3,1.1,0.2l1.5-0.4c0.5-0.1,0.9-0.1,1.4,0
              l1.5,0.4c0.7,0.2,1.5-0.3,1.7-1.1c0.1-0.4,0-0.8-0.2-1.2l-2.6-3.8c-0.4-0.6-1.1-0.8-1.7-0.4c-0.2,0.1-0.3,0.2-0.4,0.4L15,12.4z"></path>
                              </g>
                            </g>
                            <g>
                              <ellipse fill="none" stroke="currentColor" stroke-width="0.8" cx="25.7" cy="13.5" rx="1.6" ry="2"></ellipse>
                              <ellipse fill="none" stroke="currentColor" stroke-width="0.8" cx="22.7" cy="9.6" rx="1.6" ry="2"></ellipse>
                              <ellipse fill="none" stroke="currentColor" stroke-width="0.8" cx="18.3" cy="9.6" rx="1.6" ry="2"></ellipse>
                              <ellipse fill="none" stroke="currentColor" stroke-width="0.8" cx="15.5" cy="13.5" rx="1.6" ry="2"></ellipse>
                            </g>
                          </svg>
                          <p>Aceita Pet</p>
                        </div>

                        <div>
                          <p>{pet == true ? "Sim" : "Não"}</p>
                        </div>

                      </div>

                      <div className='cardImmobileCharacter'>

                        <div className='columPrimaryCardImmobileCharacter'>
                          <img src={portariaIcon} className='icon_immobile_character' />

                          <p>Portaria 24h</p>
                        </div>

                        <div>
                          <p>{kitchen == true ? "Sim" : "Não"}</p>
                        </div>

                      </div>
                    </div>


                  </div>

                  <div>
                    <p className='textThreeImmobileContainerCharacter'>Utilidade de propriedade</p>
                  </div>

                  <div className='rowCharacterImmobile'>

                    <div>
                      <div className='cardImmobileCharacter'>

                        <div className='columPrimaryCardImmobileCharacter'>
                          <img src={sacadaIcon} className='icon_immobile_character' />

                          <p>Sacada</p>
                        </div>

                        <div>
                          <p>{yard == true ? "Sim" : "Não"}</p>
                        </div>

                      </div>

                      <div className='cardImmobileCharacter'>

                        <div className='columPrimaryCardImmobileCharacter'>
                          <img src={quadraIcon} className='icon_immobile_character' />
                          <p> Quadra poliesportiva</p>
                        </div>

                        <div>
                          <p>{serviceArea == true ? "Sim" : "Não"}</p>
                        </div>

                      </div>

                      <div className='cardImmobileCharacter'>

                        <div className='columPrimaryCardImmobileCharacter'>
                          <img src={casaIcon} className='icon_immobile_character' />
                          <p>Edicula</p>
                        </div>

                        <div>
                          <p>{haveWater == true ? "Sim" : "Não"}</p>
                        </div>

                      </div>
                    </div>

                    <div>
                      <div className='cardImmobileCharacter'>

                        <div className='columPrimaryCardImmobileCharacter'>
                          <img src={piscinaIcon} className='icon_immobile_character' />
                          <p>Piscina</p>
                        </div>

                        <div>
                          <p>{energy == true ? "Sim" : "Não"}</p>
                        </div>

                      </div>

                      <div className='cardImmobileCharacter'>

                        <div className='columPrimaryCardImmobileCharacter'>
                          <img src={salaoIcon} className='icon_immobile_character' />
                          <p>Salão de festas</p>
                        </div>

                        <div>
                          <p>{laundry == true ? "Sim" : "Não"}</p>
                        </div>

                      </div>

                      <div className='cardImmobileCharacter'>

                        <div className='columPrimaryCardImmobileCharacter'>
                          <img src={kitchenIcon} style={{ width: "18px", height: "18px" }} />

                          <p>Churrasqueira</p>
                        </div>

                        <div>
                          <p>{barbecue == true ? "Sim" : "Não"}</p>
                        </div>

                      </div>
                    </div>


                  </div>




                </div>
              </div>


            </div>

            <div className='columSecondaryImmobileContainer'>
              <div className='containerPriceColumSecondary' >
                <div className='cardPriceColumSecondary' >
                  <p className='textPriceCardPrice'>Preço</p>
                  <p className='textPriceCardPriceSecondary'>R$ {formatedValue}</p>
                </div>

                <div className='cardPriceColumSecondary' >
                  <p className='textPriceCardPriceCode'>Código Imóvel</p>
                  <p className='textPriceCardPriceCode'>{code}</p>
                </div>


              </div>

              <div className='containerDescriptionImmobileColumSecondary' >
                <div className='cardPriceColumSecondary' >
                  <p className='textPriceCardPrice'>Informações imóvel</p>
                </div>

                <div className='cardPriceColumSecondary'>
                  <p className='textPriceCardPriceCode'>Cep:</p>
                  <p className='textPriceCardPriceCode line'>{zipCode}</p>
                </div>


                <div className='cardPriceColumSecondary'>
                  <p className='textPriceCardPriceCode'>Estado:</p>
                  <p className='textPriceCardPriceCode line'>{state}</p>
                </div>


                <div className='cardPriceColumSecondary'>
                  <p className='textPriceCardPriceCode'>Cidade:</p>
                  <p className='textPriceCardPriceCode line'>{city}</p>
                </div>



                <div className='cardPriceColumSecondary'>
                  <p className='textPriceCardPriceCode'>Número:</p>
                  <p className='textPriceCardPriceCode line'>{number}</p>
                </div>


              </div>



            </div>


          </section>

          <div class="modal fade" id="modal_image" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  ...
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary">Save changes</button>
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: "7rem" }}></div>


          <Footer />
        </div>
      }

    </>
  )
}

export default Immobile