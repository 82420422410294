import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AppStack from './routes/AppStack';

import MenuProvider from './context/menu';
import InputProvider from './context/input';
import SearchProvider from './context/search';
import ImagesProvider from './context/images';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ImagesProvider>
    <SearchProvider>
    <InputProvider>
    <MenuProvider>
    <AppStack />
    </MenuProvider>
    </InputProvider>
    </SearchProvider>
    </ImagesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
