import React from 'react'
import animationGif from '../../images/png/untitled-5.gif'

const AnimatePage = () => {
   
    setTimeout(() => {
        window.location.href="/immobile"
    },[2000])
    

    return(
        <section className='animation'>
            <img src={animationGif}/>
        </section>
        
    )
}

export default AnimatePage