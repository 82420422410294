import React,{createContext,useEffect,useState} from 'react'
import api from '../services/api'
import { toast} from 'react-toastify';

export const InputContext = createContext({})

function InputProvider({children}){

    const [type,setType] = useState("Comprar")
    const [minValue,setMinValue] = useState()
    const [maxValue,setMaxValue] = useState()
    const [animation,setAnimation] = useState(false)
    const [immobilesFilter,setImmobilesFilter] = useState([])

    const storageValues = (min,max) => {

        localStorage.setItem("@MIN_VALUE",min)
        localStorage.setItem("@MAX_VALUE",max)
    }

    const filter = async (typeImmobile,bead_num,option,code,min,max) => {

        setAnimation(true)
        setMinValue(min)
        setMaxValue(max)
        const response = await api.post("client/immobiles/filter/",{
            "minValue": localStorage.getItem("@MIN_VALUE"),
            "maxValue":localStorage.getItem("@MAX_VALUE"),
            "typeImmobile":typeImmobile,
            "bead_num":bead_num,
            "goal":option,
            "code":code
        })

        
        if(response.status == 200){
            setTimeout(() => {
                setAnimation(false)
            },[2000])

            localStorage.setItem("@ARRAY_IMMMOBILES", JSON.stringify(response.data));
            window.location.href="/immobile/search"   
        }

        if(response.status == 404){
            setAnimation(false)
             toast.info('Nenhum imóvel foi encontrado!', {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        }

            setTimeout(() => {
                setAnimation(false)
            },[2000])

    }

    const update = (e) => {

        setType(e)
        
    }

    return(
    <InputContext.Provider value={{type,update,filter,storageValues,animation,immobilesFilter}}>
        {children}
    </InputContext.Provider>
    )

}

export default InputProvider