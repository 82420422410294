import React,{useContext} from 'react'
import companyIcon from '../../images/png/49fff0ec-610e-4b4a-bcfe-4d03f4bb43c3.png'
import { MenuContext } from '../../context/menu'
import { useNavigate } from 'react-router-dom'
const Sidebar = () => {

    const navigate = useNavigate()

    const {isOpen,update} = useContext(MenuContext)

    const change = () =>{
        setTimeout(() => {
            update()
        },1000) 
    }
    const redirectToLandingPage = () =>{

        window.location.href="/"
    }

    const redirectToAboutPage = () => {
        window.location.href="/about"
    }

    const redirectToContactPage = () => {
        window.location.href="/contact"

    }
    return(

        <div className='container_sidebar_mobile'>
        {isOpen == true 
        
        ?
        <div className='sidebar_mobile_open'>



            <div style={{display:"flex",justifyContent:"center"}}>
            <img src={companyIcon} style={{width:"120px",marginBottom:"2rem"}}/>
            </div>

            <ul>
                <li onClick={() => redirectToLandingPage()}>Home</li>
                <li  data-toggle="modal" data-target="#exampleModalCenter" onClick={() =>  change()}>Login</li>
                <li onClick={() => redirectToAboutPage()}>Sobre Nós</li>
                <li onClick={() => redirectToContactPage()}>Contato</li>

            </ul>


        </div>       
        :

        <div className='sidebar_mobile'>



        <div style={{display:"flex",justifyContent:"center"}}>
        <img src={companyIcon} style={{width:"120px",marginBottom:"2rem"}}/>
        </div>

        <ul>
            <li>Home</li>
            <li>Sobre Nós</li>
            <li>Imóveis</li>
            <li>Contato</li>

        </ul>


        </div>  

        }
      
      </div>   

    )
}

export default Sidebar