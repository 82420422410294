import React,{useContext} from 'react'
import { InputContext } from '../../context/input'
import animationGif from '../../images/png/untitled-5.gif'
const Animation = () => {
    const { type,animation } = useContext(InputContext)

    return(
        animation  
        
        ?
        <section className='animation'>
            <img src={animationGif}/>
            <p className='searchImmobilesAnimation'>Buscando imóveis...</p>
        </section>
        

        :
        <></>

    )
}

export default Animation