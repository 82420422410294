import firebase from "firebase/compat/app"
import 'firebase/compat/storage'

const firebaseConfig = {
  apiKey: "AIzaSyCa_FeL72r8st341ZP_F9jRVlz2_fw1QZU",
  authDomain: "cajamarmoveis.firebaseapp.com",
  projectId: "cajamarmoveis",
  storageBucket: "cajamarmoveis.appspot.com",
  messagingSenderId: "628853170702",
  appId: "1:628853170702:web:3ec1228dde9b95d805525f",
  measurementId: "G-TXZBP8VE9D"
};

  
  // Initialize Firebase
  const app = firebase.initializeApp(firebaseConfig);


export default app