import React from 'react'
import companyIcon from '../images/png/49fff0ec-610e-4b4a-bcfe-4d03f4bb43c3.png'
import homeIcon from '../images/png/home.png'
import addIconBlue from '../images/png/addIconBlue.png'
import addIconWhite from '../images/png/addIconWhite.png'
import homeIconBlue from '../images/png/homeIconBlue.png'
import { useNavigate } from 'react-router-dom';

const SidebarMobile = ({ state, page }) => {

    const navigate = useNavigate()

    const redirectToDashboardPage = () => {

        navigate("/dashboard")
    }

    const redirectToAddImmobile = () => {

        navigate("/dashboard/add/immobile")
    }
    const exit = () => {
        localStorage.clear()
        window.location.href="/"
    }




    return (

        state == true

            ?

            <div className='container_sidebar_mobile_open'>

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img className='company_icon_sidebar_mobile' src={companyIcon} />
                </div>


                <div className='container_options_sidebar_mobile'>

                    <div onClick={() => redirectToDashboardPage()} className={page == "home" ? "check_option_sidebar" : "not_check_option_sidebar"}>

                        <img src={page == "home" ? homeIcon : homeIconBlue} />

                        <p style={{ color: page == "home" ? "white" : "" }}>Meus Imóveis</p>
                    </div>


                    <div onClick={() => redirectToAddImmobile()} className={page == "add" ? "check_option_sidebar" : "not_check_option_sidebar"}>
                        <img src={page == "add" ? addIconWhite : addIconBlue} />
                        <p style={{ color: page == "add" ? "white" : "" }}>Adicionar imóvel</p>
                    </div>

                    <div className='container_exit_icon_sidebar_mobile' onClick={() => exit()}>
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.10249 16.5727H3.94583C3.52723 16.5727 3.12577 16.4064 2.82978 16.1104C2.53378 15.8144 2.36749 15.4129 2.36749 14.9943V3.94601C2.36749 3.52741 2.53378 3.12595 2.82978 2.82996C3.12577 2.53396 3.52723 2.36768 3.94583 2.36768H7.10249" stroke="#123164" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12.6266 13.4156L16.5725 9.46976L12.6266 5.52393" stroke="#123164" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M16.5725 9.47021H7.10248" stroke="#123164" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <p>Sair</p>
                    </div>




                </div>

            </div>
            :

            <div className='container_sidebar_mobile'>

                <img className='company_icon_sidebar_mobile' src={companyIcon} />


                <div className='container_options_sidebar_mobile'>

                    <div>
                        <p>Inicio</p>
                    </div>


                    <div>
                        <p>Adicionar Imóvel</p>
                    </div>



                </div>

            </div>

    )
}

export default SidebarMobile