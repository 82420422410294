import React from 'react'

const InputContact = ({ name, type,onChange,value}) => {


    return (
        type == "description"

            ?
            <div  className='container_description_input_contact'>
                <p className='textPrimaryDescription_input_contact'>{name}</p>
                <textarea style={{outline:"none",background:"#EFEFEF",border:"none",paddingLeft:"1rem",paddingTop:"1rem",width:"100%"}} onChange={onChange} maxLength={150} className='input_contact_description' type='text' />
                <p className='textCount_containerDescription'>{value != undefined ?String(value).length : 0}/150</p>

            </div>

            :

            <div>
                <p>{name}</p>
                <input  onChange={onChange} className='input_contact' type='text' />
            </div>

    )

}

export default InputContact