import React from 'react'

const SelectMobile = ({ type, onChange }) => {

    return (

        <select onChange={onChange} className='selectMobile'>

            {type == "finalidade"

                ?
                <option style={{ textAlign: "center" }}>Finalidade</option>
                :
                type == "tipo"
                    ?
                    <option style={{ textAlign: "center" }}>Tipo</option>
                    :
                    type == "dormitorios"

                        ?

                        <option style={{ textAlign: "center" }}>Dormitorios</option>

                        :
                        type == "banheiros"

                            ?
                            <option style={{ textAlign: "center" }}>Banheiros</option>

                            :
                            <></>
            }

            {type == "finalidade"

                ?
                <>

                    <option className="option_selectContainerPrimary" value={1}>Comprar</option>
                    <option className="option_selectContainerPrimary" value={2}>Alugar</option>
                </>
                :
                type == "tipo"

                    ?

                    <>

                        <option className="option_selectContainerPrimary" >Casa</option>
                        <option className="option_selectContainerPrimary" >Apartamento</option>
                        <option className="option_selectContainerPrimary" >Área</option>
                        <option className="option_selectContainerPrimary" >Chácara</option>
                        <option className="option_selectContainerPrimary" >Conjunto</option>
                        <option className="option_selectContainerPrimary" >Galpão</option>
                        <option className="option_selectContainerPrimary" >Prédio</option>
                        <option className="option_selectContainerPrimary" >Sala</option>
                        <option className="option_selectContainerPrimary" >Salão</option>
                        <option className="option_selectContainerPrimary" >Sitio</option>
                        <option className="option_selectContainerPrimary" >Sobrado</option>
                        <option className="option_selectContainerPrimary" >Terreno</option>

                    </>

                    :

                    type == "dormitorios"

                        ?
                        <>
                            <option className="option_selectContainerPrimary" value={1} >1+</option>
                            <option className="option_selectContainerPrimary" value={2}>2+</option>
                            <option className="option_selectContainerPrimary" value={3}>3+</option>
                            <option className="option_selectContainerPrimary" value={4}>4+</option>
                        </>
                        :

                        type == "banheiros"

                            ?
                            <>
                                <option className="option_selectContainerPrimary" value={1} >1+</option>
                                <option className="option_selectContainerPrimary" value={2} >2+</option>
                                <option className="option_selectContainerPrimary" value={3} >3+</option>
                                <option className="option_selectContainerPrimary" value={4} >4+</option>
                            </>

                            :
                            type == "finalidade"

                                ?
                                <>
                                    <option className="option_selectContainerPrimary" value={1} >Comprar</option>
                                    <option className="option_selectContainerPrimary" value={2} >Alugar</option>
                                </>
                                :


                                <></>
            }

        </select>

    )
}

export default SelectMobile