import React,{createContext,useState} from 'react'

import api from '../services/api'

export const SearchContext = createContext({})

function SearchProvider({children}){

    const [search,setSearch] = useState()
    const [dataFilter,setDataFilter] = useState()

    const getSearch = async (value) => {

        const response = await api.post("client/immobiles/by/code",{
            "code":value
        })

        if(response.status == 200){
            setDataFilter(response.data)
        }
        setSearch(value)        
    }


    
    return(
    <SearchContext.Provider value={{getSearch,search,dataFilter}}>
        {children}
    </SearchContext.Provider>
    )

}

export default SearchProvider