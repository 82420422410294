import React,{createContext,useState} from 'react'

export const ImagesContext = createContext({})

function ImagesProvider({children}){

    const [images,setImages] = useState()
    
    const update = (status) => {

        setImages(status)

    }

    return(
    <ImagesContext.Provider value={{update,images}}>
        {children}
    </ImagesContext.Provider>
    )

}

export default ImagesProvider