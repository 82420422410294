import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

/* PAGES */
import Landing from '../pages/Landing'
import Immobile from '../pages/Immobile'
import AnimatePage from '../pages/AnimatePage'
import SearchImmobiles from '../pages/SearchImmobiles'
import Dashboard from '../pages/Dashboard'
import About from '../pages/About'
import Contact from '../pages/Contact'

import AddImmobile from '../pages/AddImmobile'
import AddImmobileMobile from '../pages/AddImmobileMobile'

const AppStack = () => {

    return(
        <Router>
            <Routes>
            <Route path='/' element={<Landing/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/immobile' element={<Immobile/>}/>
            <Route path='/immobile/search' element={<SearchImmobiles/>}/>
            <Route path='/dashboard' element={<Dashboard/>}/>
            <Route path='/dashboard/immobile' element={<AddImmobile/>}/>
            <Route path='/dashboard/add/immobile' element={<AddImmobileMobile/>}/>

            <Route path='/animate' element={<AnimatePage/>}/>

            </Routes>
        </Router>
        
    )
}

export default AppStack