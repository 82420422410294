import React, { useEffect, useState } from "react";

import Slider from "react-slick";
import nextArrow from '../../images/png/arrow-icon.png'
import backArrow from '../../images/png/arrow-icon-back.png'
import Card from "../Card";
import api from "../../services/api";
const CarroselImmobilesMobile = ({type}) => {

  // Componentes de seta personalizados
  const CustomPrevArrow = (props) => (
    <div {...props} className="custom-prev-arrow">
      <img src={backArrow} />

    </div>
  );

  const CustomNextArrow = (props) => (
    <div {...props} className="custom-next-arrow">
      <img src={nextArrow} />
    </div>
  );
  const settings = {
    dots: false,
    slidesToShow: 1,
    speed: 1000,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />, // Adicione componentes de seta personalizados se necessário

  };
  const [images, setImages] = useState([])

  const get = async () => {
    const response = await api.get(`client/immobiles/info/by/${type}`)

    if (response.status == 404) {
      return false
    }
    
    setImages(response.data)
  }

  useEffect(() => {
    get()

  }, [])



  return (
    <div className="container_sliderImmobiles_mobile">
      <Slider {...settings}>
      
        {images.map(item => {
        
            return <Card key={item.id} id_prop={item.id_prop} emphasis={item.emphasis} description={item.description} bead_num={item.bead_num} bathroom_num={item.bathroom_num}  square_meters={item.square_meters}value={item.value} />

        
        })}
      </Slider>
    </div>
  );
};

export default CarroselImmobilesMobile;