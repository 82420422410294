import React, { useEffect, useState } from "react";

import Slider from "react-slick";
import nextArrow from '../../images/png/arrow-icon.png'
import backArrow from '../../images/png/arrow-icon-back.png'
import Modal from 'react-bootstrap/Modal';
import Skeleton from 'react-loading-skeleton'

import api from "../../services/api";
const AutoPlayMobile = () => {

  const [isLoading,setIsLoading] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [slideIndex, setSlideIndex] = useState(0)

  const handleImageClick = (item) => {
    setSlideIndex(item)
    setModalShow(true)
  }

  // Componentes de seta personalizados
  const CustomPrevArrow = (props) => (
    <div {...props} className="custom-prev-arrow">
      <img src={backArrow} />

    </div>
  );

  const CustomNextArrow = (props) => (
    <div {...props} className="custom-next-arrow">
      <img src={nextArrow} />
    </div>
  );
  const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite:false, // ou true, dependendo do comportamento desejado

    cssEase: "linear",
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />, // Adicione componentes de seta personalizados se necessário

  };

  const settingsModal = {
    dots: false,
    infinite: false, // ou true, dependendo do comportamento desejado
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: slideIndex,
    cssEase: "linear",
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />, // Adicione componentes de seta personalizados se necessário

  };
  const [images, setImages] = useState([])

  const get = async () => {

    setIsLoading(true)
    const response = await api.get(`client/immobiles/images/${localStorage.getItem("@IMMOBILE_ID")}`)
    if(response.status == 200){
      setIsLoading(false)
      setImages(response.data)    

    }

    // const arrayImmobilesString = localStorage.getItem("@ARRAY_IMAGES_IMMMOBILE");
    // // Verifique se há dados no localStorage
    // if (arrayImmobilesString) {
    //     // Converta a string JSON de volta para um objeto ou array
    //     const arrayImmobiles = JSON.parse(arrayImmobilesString);

    //     setImages(arrayImmobiles)


    // } else {
    //     console.log("Não há dados no localStorage para '@ARRAY_IMAGES'.");
    // }
  }
  useEffect(() => {
    get()

  }, [])


  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

        <Modal.Body className="modal_body_image" >
          <Slider  {...settingsModal}>
            
            {images.map((item, index) => {
              return (
                
                <img src={item.image} className="image_modal_show" />
              )
            })}
          </Slider>
        </Modal.Body>

      </Modal>
    );
  }


  return (

    
    <div className="container_slider_auto_play_mobile">
   
      {isLoading == true 
      
      ?
    
      <div className="container_skeleton_autoplay">
        <div>
        <Skeleton style={{ width:"470px",height: "320px", borderRadius: "5px" }} />
        </div>

      
      </div>
      :
      <></>
      }
   
      <Slider {...settings}>
      
        {images.map((item,index) => {
          return (
            <div className="contaienr_image_slider">

              <img  onClick={() => handleImageClick(index)}  src={item.image} className="image_slider" />
            </div>
          )
        })}
      </Slider>


      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

    </div>
  );
};

export default AutoPlayMobile;