import React, { useEffect, useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Slider from "react-slick";
import nextArrow from '../../images/png/arrow-icon.png'
import backArrow from '../../images/png/arrow-icon-back.png'
import { ImagesContext } from "../../context/images";
import Skeleton from 'react-loading-skeleton'

import api from "../../services/api";
const AutoPlay = () => {

  const { update } = useContext(ImagesContext)

  const [images, setImages] = useState([])
  const [modalShow, setModalShow] = useState(false)
  const [slideIndex, setSlideIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const handleNextArrow = () => {

    if (slideIndex >= images.length - 1) {
      // Se o índice do slide for maior ou igual ao número total de imagens menos 1,
      // significa que estamos na última imagem, então não fazemos nada.
      return;
    }
    // Se não estivermos na última imagem, aumentamos o índice do slide em 1.
    setSlideIndex(slideIndex + 1);
  };

  const handleBackArrow = () => {
    console.log(slideIndex)
    if (slideIndex == 0) {
      // Se o índice do slide for maior ou igual ao número total de imagens menos 1,
      // significa que estamos na última imagem, então não fazemos nada.
      return;
    }
    // Se não estivermos na última imagem, aumentamos o índice do slide em 1.
    setSlideIndex(slideIndex - 1);
  };

  // Componentes de seta personalizados
  const CustomPrevArrow = (props) => (
    <div {...props} className="custom-prev-arrow">
      <img src={backArrow} onClick={() => handleBackArrow()} />

    </div>
  );

  const handleImageClick = (item) => {
    setSlideIndex(item)
    setModalShow(true)
  }

  const CustomNextArrow = (props) => (
    <div {...props} className="custom-next-arrow">
      <img src={nextArrow} onClick={() => handleNextArrow()} />
    </div>
  );
  const settings = {
    dots: false,
    infinite: false, // ou true, dependendo do comportamento desejado
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: "linear",
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />, // Adicione componentes de seta personalizados se necessário

  };
  const settingsModal = {
    dots: false,
    infinite: false, // ou true, dependendo do comportamento desejado
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: slideIndex,
    cssEase: "linear",
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />, // Adicione componentes de seta personalizados se necessário

  };


  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}

        size="lg"
        dialogClassName="custom_image_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

        <Modal.Body className="modal_body_image" >
          <Slider  {...settingsModal}>

            {images.map((item, index) => {
              return (

                <img src={item.image} className="image_modal_show" />
              )
            })}
          </Slider>
        </Modal.Body>

      </Modal>
    );
  }


  const get = async () => {

    const response = await api.get(`client/immobiles/images/${localStorage.getItem("@IMMOBILE_ID")}`)

    if (response.status == 200) {
      update(200)
      setImages(response.data)
      setIsLoading(false)

    }


  }

  useEffect(() => {
    get()

  }, [])



  return (
    <div className="container_slider">
      
      {isLoading 
      
      ?

      <>

      <div className="container_skeleton_autoplay">
        <div>
        <Skeleton style={{ width:"470px",height: "320px", borderRadius: "5px" }} />
        </div>
        <div>
        <Skeleton style={{ width:"470px",height: "320px", borderRadius: "5px" }} />
        </div>
        <div>
        <Skeleton style={{ width:"470px",height: "320px", borderRadius: "5px" }} />
        </div>

        <div>
        <Skeleton style={{ width:"470px",height: "320px", borderRadius: "5px" }} />
        </div>

        

        
      </div>
    </>
    
      :

      <></>
    
      }

      <Slider {...settings}>
        {isLoading

          ?

          <></>

          :

          images.map((item, index) => {
            return (
              <div className="contaienr_image_slider">
                <img onClick={() => handleImageClick(index)} src={item.image} className="image_slider" />
              </div>
            )
          })

        }

      </Slider>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

    </div>
  );
};

export default AutoPlay;