import React from 'react'
import arrowBottomIcon from '../../images/png/arrowBottom.png'
const Input = ({ name, type, border, onChange }) => {

    return (

        border == true

            ?
            <div className='container_select'>
                <p className='textPrimaryContainerSelect'>{name}</p>

                <select onChange={onChange} style={{color:"black"}} className='selectContainerPrimary_inputs_border'>

                    {type == "finalidade"

                        ?

                        <option className="option_selectContainerPrimary">Tipo</option>
                        :
                        type == "tipo"

                            ?
                            <option className="option_selectContainerPrimary">Tipo do Imóvel</option>

                            :
                            type == "banheiros" 
                            
                            ?

                            <option className="option_selectContainerPrimary">Banheiros</option>
                            :
                            type == "dormitorios"
                            ?
                            <option className="option_selectContainerPrimary">Dormitorios</option>

                            : 

                            <></>
                    }



                    {type == "finalidade"

                        ?
                        <>

                            <option className="option_selectContainerPrimary" value={1}>Comprar</option>
                            <option className="option_selectContainerPrimary" value={2}>Alugar</option>
                        </>
                        :
                        <></>
                    }

                    {type == "tipo"

                        ?
                        <>


                            <option className="option_selectContainerPrimary" >Casa</option>
                            <option className="option_selectContainerPrimary" >Apartamento</option>
                            <option className="option_selectContainerPrimary" >Área</option>
                            <option className="option_selectContainerPrimary" >Chácara</option>
                            <option className="option_selectContainerPrimary" >Conjunto</option>
                            <option className="option_selectContainerPrimary" >Galpão</option>
                            <option className="option_selectContainerPrimary" >Prédio</option>
                            <option className="option_selectContainerPrimary" >Sala</option>
                            <option className="option_selectContainerPrimary" >Salão</option>
                            <option className="option_selectContainerPrimary" >Sitio</option>
                            <option className="option_selectContainerPrimary" >Sobrado</option>
                            <option className="option_selectContainerPrimary" >Terreno</option>
                        </>
                        :
                        type == "dormitorios"

                            ?

                            <>

                                <option className="option_selectContainerPrimary" value={1} >1+</option>
                                <option className="option_selectContainerPrimary" value={2}>2+</option>
                                <option className="option_selectContainerPrimary" value={3}>3+</option>
                                <option className="option_selectContainerPrimary" value={4}>4+</option>
                            </>
                            :
                            type == "banheiros" 
                            
                            ?
                            <>
                                 <option className="option_selectContainerPrimary" value={1}>1+</option>
                                <option className="option_selectContainerPrimary" value={2}>2+</option>
                                <option className="option_selectContainerPrimary" value={3}>3+</option>
                                <option className="option_selectContainerPrimary" value={4}>4+</option>
                            </>
                            :

                            <></>
                    }


                </select>
                <img src={arrowBottomIcon} style={{ position: "absolute", bottom: "19px", right: "10px", width: "10px" }} />
            </div>
            :


            <div className='container_select'>
                <select  onChange={onChange} className='selectContainerPrimary_inputs'>
                    {
                        type == "finalidade"

                            ?
                            <option className="option_selectContainerPrimary">Finalidade </option>

                            :
                            type == "tipo"

                                ?
                                <option className="option_selectContainerPrimary">Tipo de imóvel </option>

                                :
                                type == "dormitorios"

                                    ?
                                    <option className="option_selectContainerPrimary">Dormitorios </option>

                                    :

                                    type == "banheiros" 
                                    
                                    ?
                                    <option className="option_selectContainerPrimary">Banheiros</option>

                                    :
                                    
                                    <></>
                    }



                    {type == "finalidade"

                        ?
                        <>
                            <option className="option_selectContainerPrimary">Comprar</option>
                            <option className="option_selectContainerPrimary">Alugar</option>
                        </>
                        :
                        <></>
                    }

                    {type == "tipo"

                        ?
                        <>


                            <option className="option_selectContainerPrimary" >Casa</option>
                            <option className="option_selectContainerPrimary" >Apartamento</option>
                            <option className="option_selectContainerPrimary" >Área</option>
                            <option className="option_selectContainerPrimary" >Chácara</option>
                            <option className="option_selectContainerPrimary" >Conjunto</option>
                            <option className="option_selectContainerPrimary" >Galpão</option>
                            <option className="option_selectContainerPrimary" >Prédio</option>
                            <option className="option_selectContainerPrimary" >Sala</option>
                            <option className="option_selectContainerPrimary" >Salão</option>
                            <option className="option_selectContainerPrimary" >Sitio</option>
                            <option className="option_selectContainerPrimary" >Sobrado</option>
                            <option className="option_selectContainerPrimary" >Terreno</option>
                        </>
                        :
                        type == "banheiros" 
                        ?

                        <>
                            <option className="option_selectContainerPrimary" value={1} >1+</option>
                            <option className="option_selectContainerPrimary" value={2} >2+</option>
                            <option className="option_selectContainerPrimary" value={3}>3+</option>
                            <option className="option_selectContainerPrimary" value={4}>4+</option>

                        </>
                        :
                        
                        <></>
                    }


                    {type == "dormitorios"

                        ?

                        <>
                            <option className="option_selectContainerPrimary" value={1} >1+</option>
                            <option className="option_selectContainerPrimary" value={2} >2+</option>
                            <option className="option_selectContainerPrimary" value={3}>3+</option>
                            <option className="option_selectContainerPrimary" value={4}>4+</option>

                        </>

                        :
                        type == "banheiros" 
                        
                        ?

                        <>
                        <option className="option_selectContainerPrimary" value={1} >1+</option>
                        <option className="option_selectContainerPrimary" value={2} >2+</option>
                        <option className="option_selectContainerPrimary" value={3}>3+</option>
                        <option className="option_selectContainerPrimary" value={4}>4+</option>
                        </>
                        :
                        <></>
                    }


                </select>
                <img src={arrowBottomIcon} style={{ position: "absolute", bottom: "19px", right: "10px", width: "10px" }} />
            </div>
    )

}

export default Input