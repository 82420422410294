import React from 'react'
import companyIcon from '../../images/png/Design-sem-nome.png'
import facebookIcon from '../../images/png/facebook.png'
import instagramIcon from '../../images/png/instagram.png'
import twitterIcon from '../../images/png/twitter.png'

const Footer = () => {

    return (

        <div className='footer'>

            <div className='columPrimaryFooter'>

                <img src={companyIcon} className='companyIconColumPrimaryFooter' />

                <div >
                    <p className='primaryTextColumPrimaryFooter'>Institucional</p>

                    <p>Home</p>
                    <p>Sobre Nós</p>
                    <p>Politica de Privacidade</p>
                </div>

                <div>
                    <p className='primaryTextColumPrimaryFooter'>Imóveis</p>
                    <p>Lançamentos</p>
                    <p>Alugar</p>
                    <p>Comprar</p>
                </div>

            </div>

                 <div className='columPrimaryFooter'>

                <div>
                    <p className='primaryTextColumPrimaryFooter'>Social</p>
                    
                    <div className='flexColumPrimaryHeader'>
                        <img className='iconColumPrimaryFooter' src={facebookIcon}/>
                        <p>Facebook</p>
                    </div>

                    <a href='https://www.instagram.com/cajamarimoveis_ltda/?igsh=OGQ5ZDc2ODk2ZA%3D%3D' style={{color:"white",textDecoration:"none"}} target='_blank'>

                    <div className='flexColumPrimaryHeader'>
                        <img className='iconColumPrimaryFooter'  src={instagramIcon}/>
                        <p>Instagram</p>
                    </div>
                    </a>
                    
{/*                     
                    <div className='flexColumPrimaryHeader'>
                        <img className='iconColumPrimaryFooter' src={twitterIcon}/>
                        <p>Twitter</p>
                    </div> */}
 
                </div>

                <div></div>

            </div>





        </div>

    )
}

export default Footer