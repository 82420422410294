import React from 'react'
import Header from '../../components/Header'
import HeaderSecondary from '../../components/HeaderSecondary'
import familyIcon from '../../images/svg/family.svg'
import arrowIcon from '../../images/png/arrowRight.png'
const About = () => {
    return (

        <main>
            <Header background={"black"} />
            <HeaderSecondary  page="immobile" background={"black"} />

            <section className='container_about'>

                <div >
                    <p className='textPrimary_containerAbout'>Sobre nós</p>
                    <p className='textSecondary_containerAbout'>Cajamar Imóveis</p>
                    <p className='textThree_containerAbout'>Seu parceiro confiável na jornada de encontrar o lar perfeito.</p>

                    <p className='textDescription_containerAbout'>Cajamar Imóveis é sua fonte confiável
                        para encontrar a casa dos seus sonhos. 
                        Com uma equipe dedicada e experiente, estamos comprometidos em tornar sua busca por propriedades uma experiência simples e gratificante.
                    </p>

                </div>


                <img src={familyIcon} />




            </section>


        </main>

    )
}

export default About