import React, { useCallback, useEffect, useState, useRef, useContext } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { InputContext } from '../../context/input';

const MultiRangeSlider = ({ min, max, onChange, color }) => {
    const [minVal, setMinVal] = useState(min);
    const [maxVal, setMaxVal] = useState(max);
    const minValRef = useRef(null);
    const maxValRef = useRef(null);
    const range = useRef(null);
    const { storageValues } = useContext(InputContext);

    const step = 1000; // Define o passo desejado, neste caso, mil em mil

    // Convert to percentage
    const getPercent = useCallback(
        (value) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );

    // Set width of the range to decrease from the left side
    useEffect(() => {
        if (maxValRef.current) {
            const minPercent = getPercent(minVal);
            const maxPercent = getPercent(+maxValRef.current.value);

            if (range.current) {
                range.current.style.left = `${minPercent}%`;
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [minVal, getPercent]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
        if (minValRef.current) {
            const minPercent = getPercent(+minValRef.current.value);
            const maxPercent = getPercent(maxVal);

            if (range.current) {
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [maxVal, getPercent]);

    // Get min and max values when their state changes
    useEffect(() => {
        onChange({ min: minVal, max: maxVal });
        storageValues(minVal, maxVal);
    }, [minVal, maxVal, onChange]);

    return (
        <div className="container_select">
            <div className="container">
                <input
                    type="range"
                    min={min}
                    max={max}
                    step={step}
                    value={minVal}
                    ref={minValRef}
                    onChange={(event) => {
                        const value = Math.min(
                            Math.round(+event.target.value / step) * step,
                            maxVal - step
                        );
                        setMinVal(value);
                        event.target.value = value.toString();
                    }}
                    className={classnames("thumb thumb--zindex-3", {
                        "thumb--zindex-5": minVal > max - step
                    })}
                />
                <input
                    type="range"
                    min={min}
                    max={max}
                    step={step}
                    value={maxVal}
                    ref={maxValRef}
                    onChange={(event) => {
                        const value = Math.max(
                            Math.round(+event.target.value / step) * step,
                            minVal + step
                        );
                        setMaxVal(value);
                        event.target.value = value.toString();
                    }}
                    className="thumb thumb--zindex-4"
                />

                <div className="slider">
                    <div className="slider__track" />
                    <div ref={range} className="slider__range" />

                    <div className="slider__left-value" style={{ marginTop: "44px", color: color }}>
                        {Number(minVal).toLocaleString('pt-br', { style: 'currency', maximumFractionDigits: 2, minimumFractionDigits: 2, currency: 'BRL' })}
                    </div>

                    <div className="slider__right-value" style={{ marginTop: "44px", color: color }}>
                        {Number(maxVal).toLocaleString('pt-br', { style: 'currency', maximumFractionDigits: 2, minimumFractionDigits: 2, currency: 'BRL' })}
                    </div>

                </div>
            </div>
        </div>
    );
};

MultiRangeSlider.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
};

export default MultiRangeSlider;
