import React,{createContext,useState} from 'react'
import api from '../services/api'
import { ToastContainer,toast} from 'react-toastify';

export const FilterContext = createContext({})
function FilterProvider({children}){

    const [dataFilter,setDataFilter] = useState()
    
    const get = async (goal,typeImmobile,beadNum,bathroomNum) => {
        
        
        const response = await api.post("client/immobiles/filter/info",{
            "minValue": localStorage.getItem("@MIN_VALUE"),
            "maxValue":localStorage.getItem("@MAX_VALUE"),
            "typeImmobile":typeImmobile,
            "goal":goal,
            "bead_num":beadNum,
            "bathroom_num":bathroomNum,
        })

        if(response.status == 404){

            return toast.info('Nenhum imóvel foi encontrado!', {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        }


        setDataFilter(response.data)

    }

    return(
    <FilterContext.Provider value={{get,dataFilter}}>
        {children}
    </FilterContext.Provider>
    )

}

export default FilterProvider