import React,{useEffect,useState} from 'react'
import ThowColumLayout from './layouts/ThowColumLayout'
import Sidebar from './components/Sidebar'
import HeaderDashboard from './components/Header'
import TableListInventary from './components/TableListInventary'
import HeaderDashboardMobile from './components/HeaderDashboardMobile'
import { useTranslation } from 'react-i18next'
import TableListMobile from './components/TableListMobile'
import SidebarMobile from './components/SidebarMobile'
const Dashboard = () => {
    
    const [openSidebar,setOpenSidebar] = useState(false)
    
    const { t } = useTranslation()
    const containerSecondary = {

        marginTop: "1.625rem",
        display: "flex",
        width:"100%",
        flexDirection: "column",
        overFlowY: "auto",
        alignItems: "center",
        overFlowX: "hidden",

    }

    useEffect(() => {
        const div = document.getElementById("containerTable")
        const div2 = document.getElementById("test4")

        div.addEventListener("scroll", () => {

            div2.scrollLeft = div.scrollLeft

        })


    }, []);
    

    return (

        <ThowColumLayout
            name="Gerenciar Acessos"
            page="Users"
            height={"auto"}
            background="white"
            colum1={
                <Sidebar page="Inventary" />
            }

            colum2={
                <>
                    <div className='containerSecondary'>

                    <div className='container_mobile'>
                    <SidebarMobile state={openSidebar}/>

                    {openSidebar == true 
                    
                    ?
                    <div className='sidebar_open_background' onClick={() => setOpenSidebar(!openSidebar)}>
                    </div>                
                    :
                    <></>

                    }


                    <HeaderDashboardMobile onClick={() => setOpenSidebar(!openSidebar)}/>

                    <p className='textPrimary_containerMobile'>Meus Imóveis</p>
                    
                    <div style={{width:"100%",background:"white",paddingLeft:"1.46rem"}}>
                    <div style={{width:"95vw",height:"520px",overflowX:"scroll",overflowY:"hidden",position:"fixed"}}>
                    <div style={{minWidth:"1925px",paddingLeft:"0rem",mdisplay:"flex"}}>
                    <TableListMobile/>
                    </div>                
                    </div>

                    </div>

                    </div>

                    <div className='container_main'>

                        <div className='container_tablelist'>
                        <HeaderDashboard name={t('Meus Imóveis')} />

                            <TableListInventary page="Inventary" />
                        </div>
                                  
                        <div id="containerTable" className="scrollviewTable">
                                <p className='text_scrollviewTable'></p>
                            </div>

                    </div>

                    </div>
                </>
            }

        />

    )

}

export default Dashboard