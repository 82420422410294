import React from 'react'
const ThowColumLayout = ({colum1,colum2,background,page,name}) => {


    const colum1Layout = {
        gridColumnStart: '1',
        gridColumnEnd: '2',
    }

    const colum2Layout = {
        gridColumnStart: '2',
        gridColumnEnd: '2',
        
    }
    


    return(

        <div className='container_grid'>

        <div style={colum1Layout}>

        {colum1}
        </div>

        <div style={colum2Layout}>
        {colum2}
        </div> 
        
                 

        </div>

    )

}

export default ThowColumLayout