import React, { useEffect, useState, useContext } from 'react'
/* COMPONENTS */
import { FilterContext } from '../context/filter'
import { ToastContainer,toast} from 'react-toastify';

import Header from '../components/Header'
import HeaderSecondary from '../components/HeaderSecondary'
import Input from '../components/Input'
import CardImmobile from '../components/CardImmobile'
import CardImmobileMobile from '../components/CardImmobileMobile'
import MultiRangeSliderSearchImmobiles from '../components/MultiRangeSliderSearchImmobiles'
import FilterMobile from '../components/FilterMobile'
import api from '../services/api'

const LayoutSearchImmobiles = () => {
    const {dataFilter} = useContext(FilterContext)

    const [data, setData] = useState()
    const [typeImmobile,setTypeImmobile] = useState()
    const [beadNum,setBeadNum] = useState()
    const [bathroomNum,setBathroomNum] = useState()

    const [goal,setGoal] = useState()

    const get = async () => {
     
        const arrayImmobilesString = localStorage.getItem("@ARRAY_IMMMOBILES");

        // Verifique se há dados no localStorage
        if (arrayImmobilesString) {
            // Converta a string JSON de volta para um objeto ou array
            const arrayImmobiles = JSON.parse(arrayImmobilesString);

            setData(arrayImmobiles)


        } else {
            console.log("Não há dados no localStorage para '@ARRAY_IMMMOBILES'.");
        }

    }

    const filter = async () => {
        
        if(goal == undefined || goal == "Tipo"){
            return toast.error('Informe uma finalidade!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        if(typeImmobile == undefined || typeImmobile == "Tipo de imóvel"){
            return toast.error('Informe um tipo de imóvel!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        if(beadNum == undefined || beadNum == "Dormitorios"){
            return toast.error('Informe a quantidade de dormitorios!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        if(bathroomNum == undefined || bathroomNum == "Banheiros"){
            return toast.error('Informe a quantidade de banheiros!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        
        localStorage.setItem("@OPTION_SELECT_USER",goal)
        const response = await api.post("client/immobiles/filter/info",{
            "minValue": localStorage.getItem("@MIN_VALUE"),
            "maxValue":localStorage.getItem("@MAX_VALUE"),
            "typeImmobile":typeImmobile,
            "goal":goal,
            "bead_num":beadNum,
            "bathroom_num":bathroomNum,
        })

        if(response.status == 404){

            return toast.info('Nenhum imóvel foi encontrado!', {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        }


        setData(response.data)

    }

    useEffect(() => {
        get()
    }, [])

    return (

        <main>
            <ToastContainer/>
            <section className="container_searchImmobiles">
                <Header background={"black"} />
                <HeaderSecondary page={"searchImmobile"} height={"160px"} background="black" />

            
                <section className='columContainerFlexSearchImmobiles'>

                    <div className='containerOptionsSearchImmobiles'>
                        <div style={{overflowY:"scroll",height:"620px"}}>

                        <MultiRangeSliderSearchImmobiles

                            min={1000}
                            max={10000000}
                            color={"black"}
                            background="#ffca2a"
                            marginBottom="5rem"
                            onChange={() => { }}

                        />

                        <div style={{ width: "90%" }}>
                            <Input onChange={(e) => setGoal(e.target.value)} name="Finalidade" border={true} type={"finalidade"} />
                            <Input onChange={(e) => setTypeImmobile(e.target.value)} name="Tipo" border={true} type={"tipo"} />
                            <Input onChange={(e) => setBeadNum(e.target.value)} name="Dormitorios" type={"dormitorios"} border={true} />
                            <Input onChange={(e) => setBathroomNum(e.target.value)} name="Banheiros" type={"banheiros"} border={true} />

                            <buton onClick={() => filter()} className="btnContainerSearch" >Pesquisar</buton>
                        </div>
                        </div>
                    </div>

                    <div className='clean'></div>

                    
                    <FilterMobile/>
                    <div className='containerSearchImmobileMobile'>
                    <p className='textPrimarySearchImmobileRow'>
                        {localStorage.getItem("@OPTION_SELECT_USER") == 1 
                        ?
                        "Imóveis para comprar"
                        :
                        localStorage.getItem("@OPTION_SELECT_USER") == 2 
                        
                        ?
                        "Imóveis para alugar"
                        :
                        "Código Imóvel"
                        
                        }
                    </p>

            


                    {dataFilter 
                    
                    ?
                    dataFilter?.map(item => {
                        return(
                            <CardImmobileMobile name={item.name} id_prop={item.id_prop} />
                        )
                    })
                    :

                    data?.map(item => {
                        return(
                            <CardImmobileMobile name={item.name} id_prop={item.id_prop} />
                        )
                    })
                

                    }


                    </div>
                        


                    <div className='containerSearchImmobileRow'>
                        <p className='textPrimarySearchImmobileRow'>
                        {localStorage.getItem("@OPTION_SELECT_USER") == 1 
                        ?
                        "Imóveis para Comprar"
                        :
                        localStorage.getItem("@OPTION_SELECT_USER") == 2 
                        
                        ?
                        "Imóveis para Alugar"
                        :
                        "Código Imóvel"
                        
                        }
                        </p>

                        {data?.map(item => {
                            return (
                                <CardImmobile name={item.name} id_prop={item.id_prop} />

                            )
                        })}
                    </div>



                </section>

            </section>
        </main>


    )
}

export default LayoutSearchImmobiles


