import React, { useEffect, useState,useContext } from "react";

import Slider from "react-slick";
import nextArrow from '../../images/png/arrow-icon.png'
import backArrow from '../../images/png/arrow-icon-back.png'
import Card from "../Card";
import api from "../../services/api";
import { InputContext } from "../../context/input";


const CarroseIImmobilesFilter = ({type}) => {
    const {immobilesFilter } = useContext(InputContext)

  // Componentes de seta personalizados
  const CustomPrevArrow = (props) => (
    <div {...props} className="custom-prev-arrow">
      <img src={backArrow} />

    </div>
  );



  const CustomNextArrow = (props) => (
    <div {...props} className="custom-next-arrow">
      <img src={nextArrow} />
    </div>
  );
  const settings = {
    dots: false,
    infinite:false, // ou true, dependendo do comportamento desejado
    slidesToShow: 3,
    speed: 2000,
    slidesToScroll: 2,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />, // Adicione componentes de seta personalizados se necessário

  };
  const [images, setImages] = useState([])

  const get = async () => {
    const response = await api.get(`client/immobiles/info/by/${type}`)
    if (response.status == 404) {
      return false
    }
    setImages(response.data)
  }

  useEffect(() => {
    get()

  }, [])

  useEffect(() => {


  },[immobilesFilter])


  return (
    
    <div className="container_sliderImmobiles">
      <Slider {...settings}>
      
      {
      immobilesFilter.map(item => {
        return <Card id_prop={item.id_prop} emphasis={item.emphasis} description={item.description} bead_num={item.bead_num} bathroom_num={item.bathroom_num}  square_meters={item.square_meters}value={item.value} />

      })

      }      
      </Slider>
    </div>
  );
};

export default CarroseIImmobilesFilter;