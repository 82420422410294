import React from 'react'

/* COMPONENTS */
import HeaderPhone from '../HeaderPhone'
import HeaderClient from '../HeaderClient'

const Header = ({background}) => {

    return (

            <header style={{background:background}} className='primaryHeader'>
            <div style={{width:"320px"}}>

            </div>
            <HeaderClient/>
            </header>
    )
}

export default Header