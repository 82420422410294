import React from 'react'
import LayoutSearchImmobiles from '../../Layouts/LayoutSearchImmobiles'
const SearchImmobiles = () => {
    
    return(
        <LayoutSearchImmobiles/>

    )
}

export default SearchImmobiles