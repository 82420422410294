import React, { useState } from 'react'
import ThowColumLayout from './layouts/ThowColumLayout'
import SidebarMobile from './components/SidebarMobile'
import TableListAddStock from './components/TableListAddStock'
import HeaderDashboard from './components/HeaderDashboard'
import { useTranslation } from 'react-i18next'
const AddImmobileMobile = () => {
    const { t } = useTranslation()
    const [openSidebar, setOpenSidebar] = useState(false)


    const containerSecondary = {

        marginTop: "0rem",
        display: "flex",
        flexDirection: "column",
        overFlowY: "scroll",
        overFlowX: "hidden",
        alignItems: "center",

    }


    return (

        <ThowColumLayout
            name="Adicionar estoque"
            page="addStock"
            background="white"
            height={"auto"}

            colum1={

                <></>
            }


            colum2={

                <div style={containerSecondary}>

                    <SidebarMobile page={"add"} state={openSidebar} />

                    {openSidebar == true

                        ?
                        <div className='sidebar_open_background' onClick={() => setOpenSidebar(!openSidebar)}>
                        </div>
                        :
                        <></>

                    }

                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                            <HeaderDashboard onClick={() => setOpenSidebar(!openSidebar)} name={t('Adicionar Imóvel')} />

                            <TableListAddStock />
                        </div>
                    </div>

                </div>
            }

        />
    )

}

export default AddImmobileMobile