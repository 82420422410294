import React from 'react'
import ThowColumLayout from './layouts/ThowColumLayout'
import Sidebar from './components/Sidebar'
import TableListAddStock from './components/TableListAddStock'
import HeaderDashboard from './components/Header'
import { useTranslation } from 'react-i18next'
const AddStock = () => {
    const { t } = useTranslation()

    const containerSecondary = {

        marginTop: "1.625rem",
        display: "flex",
        flexDirection: "column",
        overFlowY: "scroll",
        overFlowX: "hidden",
        alignItems: "center",

    }


    return (

        <ThowColumLayout
            name="Adicionar estoque"
            page="addStock"
            background="#F9F9F9"
            height={"auto"}

            colum1={

                <Sidebar page="addStock" />

            }


            colum2={

                <div style={containerSecondary}>
                    <div style={{ width: "1000px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ width: "1020px", display: "flex", flexDirection: "column" }}>
                            <HeaderDashboard name={t('Adicionar Imóvel')} />

                            <TableListAddStock />
                        </div>
                    </div>

                </div>
            }

        />
    )

}

export default AddStock