import React, { useEffect, useState,useContext} from 'react'
import filterIcon from '../../images/png/filter.png'
import closeFilterIcon from '../../images/png/closeFilter.png'

import SelectMobile from '../../components/SelectMobile'
import MultiRangeSliderMobile from '../MultiRangeSliderMobile'
import  {FilterContext}  from '../../context/filter'
import { ToastContainer,toast} from 'react-toastify';
import api from '../../services/api'

const FilterMobile = () => {

    const {get} = useContext(FilterContext)


    const [open, setOpen] = useState(false)
    const [option, setOption] = useState(1)
    const [typeImmobile, setTypeImmobile] = useState()
    const [beadNum, setBeadNum] = useState()
    const [bathroomNum, setBathroomNum] = useState()
    const [goal, setGoal] = useState()



   const setFilter = async () =>{
    get(goal,typeImmobile,beadNum,bathroomNum)
   }

    return (

        // ... (código anterior)

<button className={open ? "filterMobile_open" : "filterMobile"}>
    {open ? (
        <div style={{padding:"15px"}}>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "3rem", paddingLeft: "1rem" }}>
                <img className='closeIconFilter' onClick={() => setOpen(false)} src={closeFilterIcon} alt="Close Filter Icon" />
            </div>

            <div>
                
                <SelectMobile onChange={(e) => setGoal(e.target.value)} type={"finalidade"} />
                <SelectMobile onChange={(e) => setTypeImmobile(e.target.value)} type={"tipo"} />
                <SelectMobile onChange={(e) => setBeadNum(e.target.value)} type={"dormitorios"} />
                <SelectMobile onChange={(e) => setBathroomNum(e.target.value)} type={"banheiros"} />

                <MultiRangeSliderMobile color={"white"} min={100} max={10000000} onChange={() => { }} />

                <button className='btnFilterRowOptionsCardInputMobile' onClick={() => {setFilter();setOpen(!open)}}>Filtrar</button>
            </div>
        </div>
    ) : (
        <img src={filterIcon} alt="Filter Icon" onClick={() => setOpen(!open)} />
    )}
</button>

// ... (código posterior)

 

    )
}

export default FilterMobile