import React, { useEffect, useState, useContext } from 'react'
/* COMPONENTS */
import Header from '../components/Header'
import HeaderSecondary from '../components/HeaderSecondary'
import CardInputs from '../components/CardInputs'
import CardInputsMobile from '../components/CardInputsMobile'

import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import api from '../services/api'
import { InputContext } from '../context/input'
import companyIcon from '../images/png/8e15abdc-0559-48b1-b2cc-7f2efaad71a4.png'

import CarroseIImmobiles from '../components/CarroseIImmobiles'
import CarroselImmobilesMobile from '../components/CarroselImmobilesMobile'
import CarroseIImmobilesFilter from '../components/CarroseIImmobilesFilter'
import Animation from '../components/Animation'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputLogin from '../components/InputLogin'

const LayoutPrimary = () => {
    const { type, animation, immobilesFilter } = useContext(InputContext)

    const [data, setData] = useState([])
    const [optionAcess, setOptionAcess] = useState("login")

    const [mail, setMail] = useState()
    const [password, setPassword] = useState()

    const [name, setName] = useState()
    const [mailRegister, setMailRegister] = useState()
    const [passwordPrimary, setPasswordPrimary] = useState()
    const [confirmPassowrd, setConfirmPassword] = useState()


    const get = async () => {
        const response = await api.get(`client/immobiles/${type}`)

        if (response.status == 404) {
            return false
        }

        setData(response.data)
    }

    const session = async () => {
        const response = await api.post("session/storage", {
            "mail": mail,
            "password": password
        })



        if (mail == undefined || mail == "") {
            return toast.error('Informe um email!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        if (password == undefined || password == "") {
            return toast.error('Informe uma senha!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        if (response.status != 200) {
            return toast.error('email ou senha inválidos!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }


        if (response.status == 200) {
            localStorage.setItem("@CLIENT_NAME", response.data.user.name)
            localStorage.setItem('@TOKEN', response.data.token)
            window.location.href = "/dashboard"
        }
    }
    const storage = async () => {


        if (passwordPrimary != confirmPassowrd) {
            return toast.error('Senhas diferentes !', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        const response = await api.post("client/storage", {
            "name": name,
            "mail": mailRegister,
            "password_hash": confirmPassowrd
        })

        if (response.status == 200) {
            localStorage.setItem("@CLIENT_NAME", name)
            localStorage.setItem('@TOKEN', response.data.token)
            window.location.href = "/dashboard"
        }
    }

    useEffect(() => {
        get()
    }, [type])

    return (
        <>
            {animation == true

                ?
                <Animation />
                :
                <>

                    <main className='container_page' >
                        <ToastContainer />

                        <Sidebar />
                        <Header />
                        <HeaderSecondary />
                        <section className='section_search'>
                            <div className='containerInputs'>
                                <p className='textPrimarySectionSearch   animate__animated animate__fadeIn'>Encontre seu imóvel</p>
                                <p className='textSecondarySectionSearch animate__animated animate__fadeIn'>Imóveis escolhidos para quem busca qualidade de vida</p>
                                <CardInputs />
                                <CardInputsMobile />
                            </div>
                        </section>
                    </main>


                    <section className='section_main'>
                        <h1 className='textPrimary_sectionMain'>Conheça nossos imóveis</h1>

                        <section className='section_carrousel'>
                            <section className='section_items_carrousel'>
                                <p className='textSecondary_sectionMain'>Imóveis em destaque</p>

                                {immobilesFilter.length > 0

                                    ?

                                    <>
                                        <CarroseIImmobilesFilter type="emphasis" />
                                    </>

                                    :

                                    <>


                                        <CarroseIImmobiles type="emphasis" />
                                        <CarroselImmobilesMobile type="emphasis" />
                                    </>

                                }

                            </section>
                        </section>

                        <section className='section_carrousel'>
                            <section className='section_items_carrousel'>
                                <p className='textSecondary_sectionMain'>Imóveis para comprar</p>

                                {immobilesFilter.length > 0

                                    ?

                                    <>
                                        <CarroseIImmobilesFilter type="buy" />
                                    </>

                                    :

                                    <>
                                        <CarroseIImmobiles type="buy" />
                                        <CarroselImmobilesMobile type="buy" />
                                    </>

                                }



                            </section>
                        </section>

                        <section className='section_carrousel'>
                            <section className='section_items_carrousel'>
                                <p className='textSecondary_sectionMain'>Imóveis para alugar</p>


                                {immobilesFilter.length > 0

                                    ?

                                    <>
                                        <CarroseIImmobilesFilter type="buy" />
                                    </>

                                    :

                                    <>
                                        <CarroseIImmobiles type="rent" />
                                        <CarroselImmobilesMobile type="rent" />
                                    </>

                                }


                            </section>
                        </section>


                        <div style={{ height: "5rem" }}></div>


                    </section>
                    <Footer />

                    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header">

                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.132821 0.274389L0.198412 0.198412C0.43891 -0.0420873 0.815252 -0.0639507 1.08045 0.132821L1.15643 0.198412L7 6.04168L12.8436 0.198412C13.1081 -0.0661372 13.537 -0.0661372 13.8016 0.198412C14.0661 0.46296 14.0661 0.891879 13.8016 1.15643L7.95832 7L13.8016 12.8436C14.0421 13.0841 14.064 13.4604 13.8672 13.7256L13.8016 13.8016C13.5611 14.0421 13.1847 14.064 12.9196 13.8672L12.8436 13.8016L7 7.95832L1.15643 13.8016C0.891879 14.0661 0.46296 14.0661 0.198412 13.8016C-0.0661372 13.537 -0.0661372 13.1081 0.198412 12.8436L6.04168 7L0.198412 1.15643C-0.0420873 0.915928 -0.0639507 0.539587 0.132821 0.274389L0.198412 0.198412L0.132821 0.274389Z" fill="currentColor"></path></svg>

                                    <img src={companyIcon} className='image_header_modal' />

                                </div>
                                <div class="modal-body">
                                    <div className='container_options_modal'>

                                        <div className='row_options_modal'>
                                            <div onClick={() => setOptionAcess("login")}><p style={{ color: "black" }}>Entrar</p></div>
                                            <div onClick={() => setOptionAcess("register")}><p>Cadastrar</p></div>
                                        </div>
                                        {optionAcess == "login"

                                            ?
                                            <div style={{ width: "190px", background: "#ed3237", height: "3px", position: "absolute", botttom: "0", zIndex: 999 }}></div>
                                            :
                                            <div style={{ width: "190px", background: "#ed3237", height: "3px", position: "absolute", botttom: "0", right: 0, zIndex: 999 }}></div>

                                        }
                                    </div>

                                    <div style={{ height: "2rem" }}></div>

                                    <div className='box_inputs_modal'>

                                        {optionAcess == "login"

                                            ?
                                            <p className='textLogin_modal'>Acesso cliente</p>
                                            :
                                            <p className='textLogin_modal'>Cadastro cliente</p>

                                        }


                                        {optionAcess == "login"
                                            ?
                                            <>
                                                <InputLogin onChange={(e) => setMail(e.target.value)} placeholder="email@exemplo.com" />
                                                <InputLogin onChange={(e) => setPassword(e.target.value)} type={"password"} placeholder="senha" />
                                            </>
                                            :
                                            <>

                                                <InputLogin onChange={(e) => setName(e.target.value)} placeholder="nome exemplo" />

                                                <InputLogin onChange={(e) => setMailRegister(e.target.value)} placeholder="email@exemplo.com" />
                                                <InputLogin onChange={(e) => setPasswordPrimary(e.target.value)} type={"password"} placeholder="senha" />
                                                <InputLogin onChange={(e) => setConfirmPassword(e.target.value)} type={"password"} placeholder="confirmar senha" />

                                            </>

                                        }

                                        {optionAcess == "login"

                                            ?

                                            <button onClick={() => session()}>Entrar</button>
                                            :
                                            <button onClick={() => storage()}>Cadastrar</button>

                                        }

                                        <p className='forgot_password_text'>Esqueci minha senha</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </>
            }

        </>



    )
}

export default LayoutPrimary


