import { create } from 'apisauce'

const api = create({
    baseURL: 'https://api-cajamar.onrender.com/'
})
  

api.addAsyncRequestTransform(response => async() =>{
    
    const token = localStorage.getItem('@TOKEN')

    if(token){

        response.headers['Authorization']  = `Bearer ${token}`

    }

})


export default api