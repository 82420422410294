import React from 'react'

import userIcon from '../../images/png/userIcon.png'
import phoneIcon from '../../images/png/phoneIcon2.png'

const HeaderClient = () => {

    return (

        <div className='containerFlex_headerClient'>

            <div className="flexColum_headerClient">

                <div className='primaryColumn_headerPhone'>
                    <img src={phoneIcon} style={{ width: "18px" }} />
                    <p className='textPrimaryColumn_headerPhone'>(11) 4647-0205</p>
                </div>

                
                <div className='primaryColumn_headerPhone_client'>
                <img src={userIcon} style={{ position: "relative", left: "20px", width: "22px", top: "1px" }} />
                <p className='textSecondaryColumn_headerClient'>Área do cliente  </p>
                </div>


                <div className='primaryColumn_headerPhone_checked'>
                    <p className='textSecondaryColumn_headerPhone' >CRECI-SP 20.081-J</p>
                </div>


            </div>
        </div>
    )
}

export default HeaderClient