import React,{useState,useEffect} from 'react'
import api from '../../../services/api'
const InputSelectFilterManager = ({name,width,top,custom,color,marginLeft,height,type,onChange,marginRight,value,disabled,page,onClick,onBlur}) => {

    const [users,setUsers] = useState([])
    const [errorReasons,setErrorReasons] = useState([])

    const getUsers = async () => {

        const response = await api.get("clients")
        setUsers(response.data)

    }

    const getErrorReasons = async () => {

        const response = await api.get("reason")
        if(response.status == 404){
            setErrorReasons([])
            return false
        }
        setErrorReasons(response.data)


    }
    const styleSelect = {

        width:width != undefined ? width :"100%",
        height:height != undefined ? height : "45px",
        color:color != undefined ? color :"",
        backgroundColor: disabled && "#f4f4f4",
        borderRadius:"10px",
        background:"white",
        paddingLeft:"12px",
        border:"1px solid #D7D7D7",
        outline:"none",
        marginBottom:"20px",        
    }

    // useEffect(() => {
    //     getUsers()
    //     getErrorReasons()

    // },[])

    return(

        <div onClick={onClick}  onBlur={onBlur} style={{position:"relative",width:`${width != undefined ? width : "100%"}`,display:"flex",flexDirection:"column",marginRight:`${marginRight != undefined ? marginRight : "21px"}`,marginLeft:marginLeft}}>
        
        {custom == true 
        
        ?
        <label style={{marginBottom:"14px"}} className="textPrimaryLabelInput">{name}</label>
    
        :
        <label  className="textLabelInputSelectFilterManager">{name}</label>
        }

        <select disabled={disabled} onChange={onChange} className="textSelectFilterManager" style={styleSelect}>

            {type == "client" 
            
            ?
            users.map(item => {
                return(
                    <option style={{fontFamily:"sans-serif"}}>{item.name}</option>

                )

            })

            :
            <></>
            }

            {value != undefined 
            
            ?
            <option style={{fontFamily:"sans-serif"}} value={value}>{value}</option>

            :
            <option style={{fontFamily:"sans-serif"}}>Selecione</option>
            }

            {page == "add" 
            
            ?
            <>
            <option style={{fontFamily:"sans-serif"}}>Comprar</option>
            <option style={{fontFamily:"sans-serif"}}>Alugar</option>
            </>
            :
            <></>
            
            }
            
            {type == "goal" && page != "add"
            
            ?
            <>
            
            <option style={{fontFamily:"sans-serif"}}>Comprar</option>
            <option style={{fontFamily:"sans-serif"}}>Alugar</option>
            
            </>
            :

            type == "addUser"

            ?
            <>
            {value == "Administrativo" 
            ?
            <>
            <option style={{fontFamily:"sans-serif"}}>Cliente</option>
            </>
            :

            value == "Cliente" 
            
            ?
            <>
            <option style={{fontFamily:"sans-serif"}}>Administrativo</option>
            <option style={{fontFamily:"sans-serif"}}>Operacional</option>
            </>
            :

            <>
            <option style={{fontFamily:"sans-serif"}}>Administrativo</option>
            <option style={{fontFamily:"sans-serif"}}>Cliente</option>
            </>
            }
  
            </>
            :
            type == "temperature" 
            
            ?
            
            <>
            <option style={{fontFamily:"sans-serif"}} >Ambiente</option>
            <option style={{fontFamily:"sans-serif"}} >Climatizado</option>
            <option style={{fontFamily:"sans-serif"}} >Refrigerado</option>
            <option style={{fontFamily:"sans-serif"}} >Congelado</option>
            <option style={{fontFamily:"sans-serif"}} >Freezer -70</option>


            </>
            
            :

            type == "typeStructure" 

            ?

            <>
            <option style={{fontFamily:"sans-serif"}} >Armário</option>
            <option style={{fontFamily:"sans-serif"}} >Bancada</option>
            <option style={{fontFamily:"sans-serif"}} >Blocado</option>
            <option style={{fontFamily:"sans-serif"}} >Caixa</option>
            <option style={{fontFamily:"sans-serif"}} >Colmeira Pallet</option>
            <option style={{fontFamily:"sans-serif"}} >Colmeira Prateleira</option>
            <option style={{fontFamily:"sans-serif"}} >Container</option>
            <option style={{fontFamily:"sans-serif"}} >Gaveteiro</option>
            <option style={{fontFamily:"sans-serif"}} >Mesa</option>
            <option style={{fontFamily:"sans-serif"}} >Porta Pallet</option>
            <option style={{fontFamily:"sans-serif"}} >Prateleira</option>
            <option style={{fontFamily:"sans-serif"}} >Freezer</option>

            </>
            
            :
            
            type == "errorreason" 
            
            ?
            errorReasons.map(item => {

                return(

                    <option style={{fontFamily:"sans-serif"}}>{item.reason}</option>

                )

            })

            :

            type == "typeImmobile" 
            
            ?

            <>
            <option style={{fontFamily:"sans-serif"}} >Casa</option>
            <option style={{fontFamily:"sans-serif"}} >Apartamento</option>
            <option style={{fontFamily:"sans-serif"}} >Área</option>
            <option style={{fontFamily:"sans-serif"}} >Chácara</option>
            <option style={{fontFamily:"sans-serif"}} >Conjunto</option>
            <option style={{fontFamily:"sans-serif"}} >Galpão</option>
            <option style={{fontFamily:"sans-serif"}} >Prédio</option>
            <option style={{fontFamily:"sans-serif"}} >Sala</option>
            <option style={{fontFamily:"sans-serif"}} >Salão</option>
            <option style={{fontFamily:"sans-serif"}} >Sitio</option>
            <option style={{fontFamily:"sans-serif"}} >Sobrado</option>
            <option style={{fontFamily:"sans-serif"}} >Terreno</option>

            </>

            :
            type == "pet" 

            ?
            <>
            <option style={{fontFamily:"sans-serif"}} value={1} >Sim</option>
            <option style={{fontFamily:"sans-serif"}} value={0} >Não</option>

            </>
            :
            
            type == "emphasis"
            ?
            <>
            {value != "0" 
            
            ?
            <>
            <option style={{fontFamily:"sans-serif"}} value={1} >Sim</option>
            <option style={{fontFamily:"sans-serif"}} value={0} >Não</option>
            </>

            :
            <>
            <option style={{fontFamily:"sans-serif"}} value={0} >Não</option>
            <option style={{fontFamily:"sans-serif"}} value={1} >Sim</option>

            </>
            }
      
            </>
            
            :
            <></>

            }
            
        </select>

        <div style={{position:"absolute",right:0,top:`${top != undefined ? top : "19px"}`,right:"19px"}}>
        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.60677 6.61C5.15396 6.61 4.70115 6.40242 4.35831 5.99494L0.140695 0.982178C-0.0468983 0.759218 -0.0468983 0.39018 0.140695 0.16722C0.328288 -0.05574 0.638787 -0.05574 0.826381 0.16722L5.04399 5.17998C5.35449 5.54902 5.85905 5.54902 6.16955 5.17998L10.3872 0.16722C10.5748 -0.05574 10.8853 -0.05574 11.0729 0.16722C11.2604 0.39018 11.2604 0.759218 11.0729 0.982178L6.85524 5.99494C6.5124 6.40242 6.05959 6.61 5.60677 6.61Z" fill="#888888"/>
        </svg>

        </div>
        


        </div>
    )

}

export default InputSelectFilterManager