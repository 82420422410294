import React, { useState, useContext, useEffect } from 'react'
import Input from '../Input'
import { ToastContainer,toast} from 'react-toastify';

import { InputContext } from '../../context/input'
import MultiRangerSlider from '../MultiRangerSlider'
const CardInputs = () => {

    const [option, setOption] = useState(1)
    const [typeImmobile, setTypeImmobile] = useState()
    const [beadNum, setBeadNum] = useState()
    const [bathRoom,setBathroom] = useState()
    const [codeImmobile,setCodeImmmobile] = useState()


    const { update, filter } = useContext(InputContext)

    const setFilter = () => {


        localStorage.setItem("@OPTION_SELECT_USER",option)


        if(option == 3){
            
            if(codeImmobile == undefined){
                   
            return toast.error('Informe um código!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            }else{

                filter(typeImmobile,beadNum,option,codeImmobile)

            }

            return false
            
        }
        if(typeImmobile == undefined || typeImmobile == "Finalidade"){
             
            return toast.error('Informe um tipo de imóvel!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            
        }

        if(beadNum == undefined || beadNum == "Dormitorios"){
             
            return toast.error('Informe a quantidade de dormitorios!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            
        }

        if(typeImmobile == undefined || beadNum == undefined){
            return false
        }

        filter(typeImmobile,beadNum,option)
    }

    useEffect(() => {

        if(option == 1){
            setCodeImmmobile()
        }

        if(option == 2){
            setCodeImmmobile()
        }

    },[option])

    return (

        <div className='container_cardInputs animate__animated animate__fadeIn' >
            <ToastContainer/>
            <div className='rowOptionCardInputsTop'>

                {option == 1
                    ?

                    <div onClick={() => { setOption(1); update("Comprar") }} className='optionCardInputsTopChecked'>

                        <p>Comprar</p>
                    </div>
                    :
                    <div onClick={() => { setOption(1); update("Comprar") }} className='optionCardInputsTop'>

                        <p>Comprar</p>
                    </div>
                }


                {option == 2

                    ?

                    <div onClick={() => { setOption(2); update("Alugar") }} className='optionCardInputsTopChecked'>

                        <p>Alugar</p>
                    </div>

                    :

                    <div onClick={() => { setOption(2); update("Alugar") }} className='optionCardInputsTop'>

                        <p>Alugar</p>
                    </div>

                }



                {option == 3

                    ?

                    <div onClick={() => { setOption(3); update("Código") }} className='optionCardInputsTopChecked'>

                        <p>Código Imóvel</p>
                    </div>

                    :

                    <div onClick={() => { setOption(3); update("Código") }} className='optionCardInputsTop'>

                        <p>Código Imóvel</p>
                    </div>

                }


            </div>

            {option == 1

                ?
                <div className='optionCardInputsBottomPurchase'>

                    <div className='rowOptionCardInputsBottomPurchase'>

                        <Input onChange={(e) => setTypeImmobile(e.target.value)} name="Tipo de imovél" type="tipo" />
                        <Input onChange={(e) => setBathroom(e.target.value)} name="Banheiros" type="banheiros" />
                        <Input onChange={(e) => setBeadNum(e.target.value)} type={"dormitorios"} />
                    </div>
                    <div className='rowOptionCardInputBottomValue'>
                        <p className='textPrimaryRowOptionCardInputBottomValue'>Faixa de valores </p>
                        <MultiRangerSlider
                            color={"white"}
                            min={1000}
                            max={90000000}
                            onChange={() => { }}
                        />
                    </div>

                    <button className='filter_btn' onClick={() => setFilter()}>Filtrar</button>


                </div>
                :

                option == 2

                    ?

                    <div className='optionCardInputsBottomPurchase'>

                    <div className='rowOptionCardInputsBottomPurchase'>

                        <Input onChange={(e) => setTypeImmobile(e.target.value)} name="Tipo de imovél" type="tipo" />
                        <Input onChange={(e) => setBathroom(e.target.value)} name="Banheiros" type="banheiros" />
                        <Input onChange={(e) => setBeadNum(e.target.value)} type={"dormitorios"} />
                    </div>
                    <div className='rowOptionCardInputBottomValue'>
                        <p className='textPrimaryRowOptionCardInputBottomValue'>Faixa de valores </p>
                        <MultiRangerSlider
                            color={"white"}
                            min={100}
                            max={90000000}
                            onChange={() => { }}
                        />
                    </div>

                    <button className='filter_btn' onClick={() => setFilter()}>Filtrar</button>


                </div>
                    :

                    option == 3

                        ?
                        <div className='optionCardInputsBottomPurchase'>

                            <div className='rowOptionCardInputsBottomPurchase'>

                            <input className='searchInputText' onChange={(e) => setCodeImmmobile(e.target.value)} placeholder="Código imóvel" type="text"/>

                            </div>


                            <button className='filter_btn' onClick={() => setFilter()}>Filtrar</button>


                        </div>

                        :

                        <></>

            }





        </div>

    )
}

export default CardInputs