import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import companyIcon from '../images/png/49fff0ec-610e-4b4a-bcfe-4d03f4bb43c3.png'

const Sidebar = ({ page }) => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()

    

    const container = {

        width: "265px",
        position: "fixed",
        top: "0",
        zIndex: "2",
        height: "100vh",
        background: "white",
        border: "1px solid #D7D7D7",
        borderTopRightRadius: 17,
        borderBottomRightRadius: 17
    }
    const containerImage = {

        display: "flex",
        justifyContent: "center",
        marginTop: "2.625rem"
    }
    const containerItem = {
        display: "flex",
        alignItems: "center",
        width: "200px",
        borderRadius: "8px",
        paddingTop: "2px",
        paddingBottom: "3px",
        height: "39px",
        background: "#123164",
        marginBottom: "21px",
        cursor: "pointer"

    }
    const containerItemNotChecked = {
        display: "flex",
        alignItems: "center",
        width: "243px",
        borderRadius: "8px",
        paddingTop: "2px",
        paddingBottom: "3px",
        height: "39px",
        background: "white",
        marginBottom: "21px",
        cursor: "pointer"

    }
    const image = {

        position: "relative",
        top: "1px",
        marginLeft: "11px"

    }

    const redirectToDashboardPage = () => {

        navigate("/dashboard")


    }
    const redirectToInventaryPage = () => {

        navigate("/dashboard")

    }
    const redirectToManagerPage = () => {

        navigate("/dashboard/immobile")

    }
    const redirectToUserPage = () => {

        navigate("/dashboard/users")

    }
    const redirectToExit = () => {
        navigate("/")
    }


    return (

        <div className='container_sidebar' style={container}>
            <div style={containerImage}>
            <img src={companyIcon} style={{ width: "150px" }} />

            </div>

            <div style={{ paddingLeft: "12px", paddingRight: "12px", marginTop: "52px" }}>

                {localStorage.getItem("@USER_PERMISSION") == "Cliente"

                    ?
                    <>


                        <div onClick={() => redirectToInventaryPage()} style={page == "Inventary" ? containerItem : containerItemNotChecked}>

                            <div style={image}>

                                {page == "Inventary"

                                    ?
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.5 16C19.5 16.553 19.053 17 18.5 17H16.5C15.947 17 15.5 16.553 15.5 16C15.5 15.447 15.947 15 16.5 15H18.5C19.053 15 19.5 15.447 19.5 16ZM24 15V20C24 22.206 22.206 24 20 24H4C1.794 24 0 22.206 0 20V15C0 12.794 1.794 11 4 11H5V4C5 1.794 6.794 0 9 0H15C17.206 0 19 1.794 19 4V11H20C22.206 11 24 12.794 24 15ZM7 11H17V4C17 2.897 16.103 2 15 2H9C7.897 2 7 2.897 7 4V11ZM4 22H11V13H4C2.897 13 2 13.897 2 15V20C2 21.103 2.897 22 4 22ZM22 15C22 13.897 21.103 13 20 13H13V22H20C21.103 22 22 21.103 22 20V15ZM7.5 15H5.5C4.947 15 4.5 15.447 4.5 16C4.5 16.553 4.947 17 5.5 17H7.5C8.053 17 8.5 16.553 8.5 16C8.5 15.447 8.053 15 7.5 15ZM14 5C14 4.447 13.553 4 13 4H11C10.447 4 10 4.447 10 5C10 5.553 10.447 6 11 6H13C13.553 6 14 5.553 14 5Z" fill="white" />
                                    </svg>

                                    :
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.5 16C19.5 16.553 19.053 17 18.5 17H16.5C15.947 17 15.5 16.553 15.5 16C15.5 15.447 15.947 15 16.5 15H18.5C19.053 15 19.5 15.447 19.5 16ZM24 15V20C24 22.206 22.206 24 20 24H4C1.794 24 0 22.206 0 20V15C0 12.794 1.794 11 4 11H5V4C5 1.794 6.794 0 9 0H15C17.206 0 19 1.794 19 4V11H20C22.206 11 24 12.794 24 15ZM7 11H17V4C17 2.897 16.103 2 15 2H9C7.897 2 7 2.897 7 4V11ZM4 22H11V13H4C2.897 13 2 13.897 2 15V20C2 21.103 2.897 22 4 22ZM22 15C22 13.897 21.103 13 20 13H13V22H20C21.103 22 22 21.103 22 20V15ZM7.5 15H5.5C4.947 15 4.5 15.447 4.5 16C4.5 16.553 4.947 17 5.5 17H7.5C8.053 17 8.5 16.553 8.5 16C8.5 15.447 8.053 15 7.5 15ZM14 5C14 4.447 13.553 4 13 4H11C10.447 4 10 4.447 10 5C10 5.553 10.447 6 11 6H13C13.553 6 14 5.553 14 5Z" fill="#123164" />
                                    </svg>

                                }

                            </div>

                            {page == "Inventary"

                                ?

                                <p className="textCheckedSidebar">Imóveis</p>
                                :
                                <p className="textNotCheckedSidebar">Imóveis</p>

                            }


                        </div>

                        <div onClick={() => redirectToExit()} style={{ ...containerItemNotChecked, marginTop: "50px", marginBottom: "50px"}}>

                            <div style={image}>

                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.10249 16.5727H3.94583C3.52723 16.5727 3.12577 16.4064 2.82978 16.1104C2.53378 15.8144 2.36749 15.4129 2.36749 14.9943V3.94601C2.36749 3.52741 2.53378 3.12595 2.82978 2.82996C3.12577 2.53396 3.52723 2.36768 3.94583 2.36768H7.10249" stroke="#123164" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12.6266 13.4156L16.5725 9.46976L12.6266 5.52393" stroke="#123164" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16.5725 9.47021H7.10248" stroke="#123164" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>



                            <p style={{ marginBottom: "8px" }} className="textNotCheckedSidebar">Sair</p>



                        </div>

                    </>
                    :

                    <>


            

                        <div onClick={() => redirectToInventaryPage()} style={page == "Inventary" ? containerItem : containerItemNotChecked}>

                            <div style={image}>

                                {page == "Inventary"

                                    ?
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.5 16C19.5 16.553 19.053 17 18.5 17H16.5C15.947 17 15.5 16.553 15.5 16C15.5 15.447 15.947 15 16.5 15H18.5C19.053 15 19.5 15.447 19.5 16ZM24 15V20C24 22.206 22.206 24 20 24H4C1.794 24 0 22.206 0 20V15C0 12.794 1.794 11 4 11H5V4C5 1.794 6.794 0 9 0H15C17.206 0 19 1.794 19 4V11H20C22.206 11 24 12.794 24 15ZM7 11H17V4C17 2.897 16.103 2 15 2H9C7.897 2 7 2.897 7 4V11ZM4 22H11V13H4C2.897 13 2 13.897 2 15V20C2 21.103 2.897 22 4 22ZM22 15C22 13.897 21.103 13 20 13H13V22H20C21.103 22 22 21.103 22 20V15ZM7.5 15H5.5C4.947 15 4.5 15.447 4.5 16C4.5 16.553 4.947 17 5.5 17H7.5C8.053 17 8.5 16.553 8.5 16C8.5 15.447 8.053 15 7.5 15ZM14 5C14 4.447 13.553 4 13 4H11C10.447 4 10 4.447 10 5C10 5.553 10.447 6 11 6H13C13.553 6 14 5.553 14 5Z" fill="white" />
                                    </svg>

                                    :
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.5 16C19.5 16.553 19.053 17 18.5 17H16.5C15.947 17 15.5 16.553 15.5 16C15.5 15.447 15.947 15 16.5 15H18.5C19.053 15 19.5 15.447 19.5 16ZM24 15V20C24 22.206 22.206 24 20 24H4C1.794 24 0 22.206 0 20V15C0 12.794 1.794 11 4 11H5V4C5 1.794 6.794 0 9 0H15C17.206 0 19 1.794 19 4V11H20C22.206 11 24 12.794 24 15ZM7 11H17V4C17 2.897 16.103 2 15 2H9C7.897 2 7 2.897 7 4V11ZM4 22H11V13H4C2.897 13 2 13.897 2 15V20C2 21.103 2.897 22 4 22ZM22 15C22 13.897 21.103 13 20 13H13V22H20C21.103 22 22 21.103 22 20V15ZM7.5 15H5.5C4.947 15 4.5 15.447 4.5 16C4.5 16.553 4.947 17 5.5 17H7.5C8.053 17 8.5 16.553 8.5 16C8.5 15.447 8.053 15 7.5 15ZM14 5C14 4.447 13.553 4 13 4H11C10.447 4 10 4.447 10 5C10 5.553 10.447 6 11 6H13C13.553 6 14 5.553 14 5Z" fill="#123164" />
                                    </svg>

                                }

                            </div>

                            {page == "Inventary"

                                ?

                                <p className="textCheckedSidebar">{t('Imovéis')}</p>
                                :
                                <p className="textNotCheckedSidebar">{t('Imovéis')}</p>

                            }


                        </div>


                        <div onClick={() => redirectToManagerPage()} style={page == "ManageMent" || page == "addStock" ? containerItem : containerItemNotChecked}>

                            <div style={image}>

                                {page == "ManageMent" || page == "addStock"

                                    ?
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.922 5.82288C20.7251 5.3819 20.208 5.18416 19.7662 5.38278L19.2491 5.61464V0.874964C19.2491 0.391984 18.858 0 18.3741 0C17.8903 0 17.4991 0.391984 17.4991 0.874964V6.39948L13.9993 7.97004V0.874964C13.9993 0.391984 13.6082 0 13.1243 0C12.6405 0 12.2494 0.391984 12.2494 0.874964V8.75489L8.74951 10.3254V0.874964C8.74951 0.391984 8.35753 0 7.87455 0C7.39157 0 6.99958 0.391984 6.99958 0.874964V11.1103L3.49973 12.6808V0.874964C3.49973 0.391984 3.10775 0 2.62477 0C2.14179 0 1.7498 0.391984 1.7498 0.874964V13.4657L0.516979 14.0187C0.0759977 14.2164 -0.120869 14.7335 0.0768726 15.1745C0.222117 15.4991 0.541478 15.6916 0.875714 15.6916C0.995584 15.6916 1.1172 15.6671 1.23357 15.6146L1.75068 15.3827V20.1233C1.75068 20.6071 2.14266 20.9983 2.62564 20.9983C3.10862 20.9983 3.5006 20.6071 3.5006 20.1233V14.597L7.00046 13.0273V20.1233C7.00046 20.6071 7.39244 20.9983 7.87542 20.9983C8.3584 20.9983 8.75039 20.6071 8.75039 20.1233V12.2434L12.2502 10.6737V20.125C12.2502 20.6089 12.6413 21 13.1252 21C13.6091 21 14.0002 20.6089 14.0002 20.125V9.88796L17.5 8.31828V20.125C17.5 20.6089 17.8911 21 18.375 21C18.8588 21 19.2499 20.6089 19.2499 20.125V7.53256L20.4828 6.97958C20.9238 6.78184 21.1197 6.26386 20.922 5.82288Z" fill="white" />
                                    </svg>

                                    :
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.922 5.82288C20.7251 5.3819 20.208 5.18416 19.7662 5.38278L19.2491 5.61464V0.874964C19.2491 0.391984 18.858 0 18.3741 0C17.8903 0 17.4991 0.391984 17.4991 0.874964V6.39948L13.9993 7.97004V0.874964C13.9993 0.391984 13.6082 0 13.1243 0C12.6405 0 12.2494 0.391984 12.2494 0.874964V8.75489L8.74951 10.3254V0.874964C8.74951 0.391984 8.35753 0 7.87455 0C7.39157 0 6.99958 0.391984 6.99958 0.874964V11.1103L3.49973 12.6808V0.874964C3.49973 0.391984 3.10775 0 2.62477 0C2.14179 0 1.7498 0.391984 1.7498 0.874964V13.4657L0.516979 14.0187C0.0759977 14.2164 -0.120869 14.7335 0.0768726 15.1745C0.222117 15.4991 0.541478 15.6916 0.875714 15.6916C0.995584 15.6916 1.1172 15.6671 1.23357 15.6146L1.75068 15.3827V20.1233C1.75068 20.6071 2.14266 20.9983 2.62564 20.9983C3.10862 20.9983 3.5006 20.6071 3.5006 20.1233V14.597L7.00046 13.0273V20.1233C7.00046 20.6071 7.39244 20.9983 7.87542 20.9983C8.3584 20.9983 8.75039 20.6071 8.75039 20.1233V12.2434L12.2502 10.6737V20.125C12.2502 20.6089 12.6413 21 13.1252 21C13.6091 21 14.0002 20.6089 14.0002 20.125V9.88796L17.5 8.31828V20.125C17.5 20.6089 17.8911 21 18.375 21C18.8588 21 19.2499 20.6089 19.2499 20.125V7.53256L20.4828 6.97958C20.9238 6.78184 21.1197 6.26386 20.922 5.82288Z" fill="#123164" />
                                    </svg>

                                }
                            </div>

                            {page == "ManageMent" || page == "addStock"

                                ?
                                <p className="textCheckedSidebar">{t('Adicionar Imóvel')}</p>

                                :

                                <p className="textNotCheckedSidebar">{t('Adicionar Imóvel')}</p>
                            }


                        </div>

                        <div onClick={() => redirectToExit()} style={{ ...containerItemNotChecked, marginTop: "50px", marginBottom: "50px"}}>

                            <div style={image}>

                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.10249 16.5727H3.94583C3.52723 16.5727 3.12577 16.4064 2.82978 16.1104C2.53378 15.8144 2.36749 15.4129 2.36749 14.9943V3.94601C2.36749 3.52741 2.53378 3.12595 2.82978 2.82996C3.12577 2.53396 3.52723 2.36768 3.94583 2.36768H7.10249" stroke="#123164" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12.6266 13.4156L16.5725 9.46976L12.6266 5.52393" stroke="#123164" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16.5725 9.47021H7.10248" stroke="#123164" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>



                            <p style={{ marginBottom: "8px" }} className="textNotCheckedSidebar">Sair</p>



                        </div>

                    </>

                }




            </div>
        </div>

    )

}

export default Sidebar