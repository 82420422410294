import React, { useCallback, useEffect, useState, useRef,useContext } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { InputContext } from '../../context/input'

const MultiRangeSliderSearchImmobiles = ({ min, max, onChange,color,marginBottom,background }) => {
    const [minVal, setMinVal] = useState(min);
    const [maxVal, setMaxVal] = useState(max);
    const minValRef = useRef(null);
    const maxValRef = useRef(null);
    const range = useRef(null);
    const {filter} = useContext(InputContext)

    // Convert to percentage
    const getPercent = useCallback(
        (value) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );

    // Set width of the range to decrease from the left side
    useEffect(() => {
        if (maxValRef.current) {
            const minPercent = getPercent(minVal);
            const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

            if (range.current) {
                range.current.style.left = `${minPercent}%`;
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [minVal, getPercent]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
        if (minValRef.current) {
            const minPercent = getPercent(+minValRef.current.value);
            const maxPercent = getPercent(maxVal);

            if (range.current) {
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [maxVal, getPercent]);

    // Get min and max values when their state changes
    useEffect(() => {
        onChange({ min: minVal, max: maxVal });

        localStorage.setItem("@MIN_VALUE",String(minVal))
        localStorage.setItem("@MAX_VALUE",String(maxVal))
    }, [minVal, maxVal, onChange]);

    return (
        <div className="container_select" style={{marginBottom:marginBottom,width:"390px"}}>
            <p className="textPrimaryContainerSelect">Faixa de valores </p>
            <div className="container" style={{width:"100%"}}>

                <input
                    type="range"
                    min={min}
                    max={max}
                    value={minVal}
                    ref={minValRef}
                    onChange={(event) => {
                        const value = Math.min(+event.target.value, maxVal - 1);
                        setMinVal(value);
                        event.target.value = value.toString();
                    }}
                    className={classnames("thumb thumb--zindex-3", {
                        "thumb--zindex-5": minVal > max - 100
                    })}
                />
                <input
                    type="range"
                    min={min}
                    max={max}
                    value={maxVal}
                    ref={maxValRef}
                    onChange={(event) => {
                        const value = Math.max(+event.target.value, minVal + 1);
                        setMaxVal(value);
                        event.target.value = value.toString();
                    }}
                    className="thumb thumb--zindex-4"
                />

                <div className="slider">
                    <div className="slider__track" />
                    <div ref={range} className="slider__range" style={{background:background}} />
                    
                    <div className="slider__left-value" style={{marginTop:"44px",color:color}}> {Number(minVal).toLocaleString('pt-br', { style: 'currency', maximumFractionDigits:2,minimumFractionDigits:2, currency: 'BRL' })}</div>
                    
                    <div className="slider__right-value"  style={{marginTop:"44px",color:color}}>
                        


                    {Number(maxVal).toLocaleString('pt-br', { style: 'currency', maximumFractionDigits:2,minimumFractionDigits:2, currency: 'BRL' })}                        
                        
                        </div>
                
                </div>
            </div>

        </div>

    );
};

MultiRangeSliderSearchImmobiles.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
};

export default MultiRangeSliderSearchImmobiles;
