import React from 'react'

/* Layouts */
import LayoutPrimary from '../../Layouts/LayoutPrimary'
import 'react-toastify/dist/ReactToastify.css';

const Landing = () => {
    return(
        <LayoutPrimary/>
    )
}

export default Landing