import React, { useContext, useState } from 'react'
import { MenuContext } from '../../context/menu'
/* IMAGES PNG */
import companyIcon from '../../images/png/8e15abdc-0559-48b1-b2cc-7f2efaad71a4.png'
import menuMobile from '../../images/png/cardapio.png'
import closeIcon from '../../images/png/close.png'
import backIcon from '../../images/png/backIcon.png'
import api from '../../services/api'
import { useNavigate } from 'react-router-dom'
import InputLogin from '../InputLogin'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Header = ({ background, height, page }) => {

    const navigate = useNavigate()
    const [optionAcess, setOptionAcess] = useState("login")
    const [name, setName] = useState()
    const [mail, setMail] = useState()
    const [password, setPassword] = useState()

    const [mailRegister, setMailRegister] = useState()
    const [passwordPrimary, setPasswordPrimary] = useState()
    const [confirmPassowrd, setConfirmPassword] = useState()
    const { isOpen, update } = useContext(MenuContext)

    const redirectToLandingPage = () => {

        window.location.href="/"
    }

    const redirectToAboutPage = () => {
        window.location.href="/about"
    }

    const redirectToContactPage = () => {
        window.location.href="/contact"
    }

    const session = async () => {
        const response = await api.post("session/storage", {
            "mail": mail,
            "password": password
        })



        if (mail == undefined || mail == "") {
            return toast.error('Informe um email!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        if (password == undefined || password == "") {
            return toast.error('Informe uma senha!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        if (response.status != 200) {
            return toast.error('email ou senha inválidos!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }


        if (response.status == 200) {
            localStorage.setItem("@CLIENT_NAME", response.data.user.name)
            localStorage.setItem('@TOKEN', response.data.token)
            window.location.href = "/dashboard"
        }
    }
    const storage = async () => {


        if (passwordPrimary != confirmPassowrd) {
            return toast.error('Senhas diferentes !', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        const response = await api.post("client/storage", {
            "name": name,
            "mail": mailRegister,
            "password_hash": confirmPassowrd
        })

        if (response.status == 200) {
            localStorage.setItem("@CLIENT_NAME", name)
            localStorage.setItem('@TOKEN', response.data.token)
            window.location.href = "/dashboard"
        }
    }



    return (
        <>

            <section style={{ background: background, height: height, zIndex: "999" }} className='hero'>

                {page == "immobile"

                    ?
                    <img src={backIcon} className='backIcon' onClick={() => redirectToLandingPage()} />
                    :
                    page == "searchImmobile"

                        ?
                        <img src={backIcon} className='backIcon' onClick={() => redirectToLandingPage()} />

                        :

                        <></>
                }


                {isOpen == true

                    ?
                    <div style={{ width: "120px" }}></div>
                    :
                    <img src={companyIcon} className='companyIcon' />

                }

                {page == "immobile" || page == "searchImmobile"

                    ?
                    <></>
                    :
                    <div className='mobile_menu' onClick={() => update()}>

                        {isOpen == true

                            ?
                            <img src={closeIcon} className='closeIconMobile' style={{ width: "35px" }} />

                            :
                            <img src={menuMobile} className='menuIconMobile' />

                        }

                    </div>

                }

                <nav>
                    <ul>
                        <li onClick={() => redirectToLandingPage()} >Home</li>
                        <li onClick={() => redirectToAboutPage()}>Sobre nós</li>
                        <li data-toggle="modal" data-target="#exampleModalCenter">Anuncie seu imóvel</li>
                        <li onClick={() => redirectToContactPage()}>Contato</li>

                    </ul>
                </nav>


            </section>





            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-login modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">

                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.132821 0.274389L0.198412 0.198412C0.43891 -0.0420873 0.815252 -0.0639507 1.08045 0.132821L1.15643 0.198412L7 6.04168L12.8436 0.198412C13.1081 -0.0661372 13.537 -0.0661372 13.8016 0.198412C14.0661 0.46296 14.0661 0.891879 13.8016 1.15643L7.95832 7L13.8016 12.8436C14.0421 13.0841 14.064 13.4604 13.8672 13.7256L13.8016 13.8016C13.5611 14.0421 13.1847 14.064 12.9196 13.8672L12.8436 13.8016L7 7.95832L1.15643 13.8016C0.891879 14.0661 0.46296 14.0661 0.198412 13.8016C-0.0661372 13.537 -0.0661372 13.1081 0.198412 12.8436L6.04168 7L0.198412 1.15643C-0.0420873 0.915928 -0.0639507 0.539587 0.132821 0.274389L0.198412 0.198412L0.132821 0.274389Z" fill="currentColor"></path></svg>

                            <img src={companyIcon} className='image_header_modal' />

                        </div>
                        <div class="modal-body">
                            <div className='container_options_modal'>

                                <div className='row_options_modal'>
                                    <div onClick={() => setOptionAcess("login")}><p style={{ color: "black" }}>Entrar</p></div>
                                    <div onClick={() => setOptionAcess("register")}><p>Cadastrar</p></div>
                                </div>
                                {optionAcess == "login"

                                    ?
                                    <div style={{ width: "190px", background: "#ed3237", height: "3px", position: "absolute", botttom: "0", zIndex: 999 }}></div>
                                    :
                                    <div style={{ width: "190px", background: "#ed3237", height: "3px", position: "absolute", botttom: "0", right: 0, zIndex: 999 }}></div>

                                }
                            </div>

                            <div style={{ height: "2rem" }}></div>

                            <div className='box_inputs_modal'>

                                {optionAcess == "login"

                                    ?
                                    <p className='textLogin_modal'>Acesso cliente</p>
                                    :
                                    <p className='textLogin_modal'>Cadastro cliente</p>

                                }


                                {optionAcess == "login"
                                    ?
                                    <>
                                        <InputLogin onChange={(e) => setMail(e.target.value)} placeholder="email@exemplo.com" />
                                        <InputLogin onChange={(e) => setPassword(e.target.value)} type={"password"} placeholder="senha" />
                                    </>
                                    :
                                    <>

                                        <InputLogin onChange={(e) => setName(e.target.value)} placeholder="nome exemplo" />

                                        <InputLogin onChange={(e) => setMailRegister(e.target.value)} placeholder="email@exemplo.com" />
                                        <InputLogin onChange={(e) => setPasswordPrimary(e.target.value)} type={"password"} placeholder="senha" />
                                        <InputLogin onChange={(e) => setConfirmPassword(e.target.value)} type={"password"} placeholder="confirmar senha" />

                                    </>

                                }

                                {optionAcess == "login"

                                    ?

                                    <button onClick={() => session()}>Entrar</button>
                                    :
                                    <button onClick={() => storage()}>Cadastrar</button>

                                }

                                <p className='forgot_password_text'>Esqueci minha senha</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>





        </>

    )
}

export default Header