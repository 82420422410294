import React, { useEffect, useState } from 'react'
import camaIcon from '../../images/png/cama-de-casal.png'
import vasoSanitarioIcon from '../../images/png/vaso-sanitario.png'
import copiaDeIcon from '../../images/png/copia-de.png'
import api from '../../services/api'
import Skeleton from 'react-loading-skeleton'
const Card = ({ id_prop, description, bead_num, bathroom_num, square_meters, value, emphasis }) => {

    const [image, setImage] = useState()
    const [neighborhood,setNeighborhood] = useState()
    const [animation,setAnimation] = useState(false)
    const redirectToImmobilePage = async (id_prop) => {

        const response = await api.get(`client/immobiles/images/${localStorage.getItem("@IMMOBILE_ID")}`)
        

            localStorage.setItem("@IMMOBILE_ID", id_prop)
            window.location.href = "/immobile"
    
        

    }

    const getAddress = async () => {
        const response = await api.get(`client/immobiles/addresses/${id_prop}`)
        console.log(response.data)
        setNeighborhood(response.data[0].neighborhood)
        

    }

    const getImage = async () => {
        
        setAnimation(true)
        const response = await api.post("client/immobiles/image/", {
            "id_prop": id_prop
        })

        if(response.status == 200){
            setAnimation(false)
            setImage(response.data[0].image)

        }
        

    }


    useEffect(() => {

        getImage()
        getAddress()

    }, [id_prop])

    
    return (

        <div className='cardImmobiles' key={id_prop} onClick={() => redirectToImmobilePage(id_prop)}>

            <div className='cardHeader'>

                {emphasis == "1"
                    ?
                    <div className='cardEmphasis'>
                        <p className='textCardEmphasis'>Destaque</p>
                    </div>
                    :
                    <></>
                }
                {animation == true 
                
                ?
                <Skeleton  className='imageCardHeader'/>
            
                :
                <img src={image} className='imageCardHeader' />
                }
            </div>

            <div className='cardDescription'>
                <p className='textSecondaryCardDescription'>{neighborhood} </p>
                <p className='textPrimaryCardDescription'>{description}</p>
            </div>

            <div className='cardInfo'>

                <div className='cardRowInfo'>
                    <img src={camaIcon} className='cardInfoIcon' />
                    <p>{bead_num}</p>
                </div>


                <div className='cardRowInfo'>
                    <img src={vasoSanitarioIcon} style={{ width: "35px" }} className='cardInfoIcon' />
                    <p>{bathroom_num}</p>

                </div>


                <div className='cardRowInfo'>
                    <img src={copiaDeIcon} className='cardInfoIcon' />
                    <p>{square_meters}m²</p>

                </div>

            </div>

            <p className='textValueCard'>{Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>

            <div className='container_btn_show_card'>
                <button className='btn_show_card'>Ver imóvel</button>
            </div>

        </div>

    )
}

export default Card